/* eslint-disable react/jsx-no-literals */

import React from 'react';
import { AuthenticationMasterPage } from '../authentication-master/authentication-master.page';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';

export const NotFoundPage: React.FC = () => {
  return (
    <AuthenticationMasterPage>
      <h1>404</h1>
      {translate(translationKeys.errors.notFound)}
    </AuthenticationMasterPage>
  );
};
