import { List, Space } from 'antd';
import { getIconByOsType } from '../../../../../logic/utils/get-icon-by-ostype';

interface OutdatedUsersListProps {
  list: any[];
  header?: React.ReactNode;
}

const OutdatedUsersList: React.FC<OutdatedUsersListProps> = ({ list, header }) => {
  return (
    <List
      header={<Space>{header}</Space>}
      dataSource={list}
      renderItem={(item) => (
        <List.Item>
          <Space>
            {getIconByOsType(item.os_type)}
            <b>{item.version}</b>
            {`${item.first_name} ${item.last_name} (${item.email})`}
          </Space>
        </List.Item>
      )}
      size="small"
    />
  );
};

export default OutdatedUsersList;
