import { Row, Space, Select } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { BuildOutlined } from '@ant-design/icons';
import styled from 'styled-components';

import AppVersionsWidget from '../../../components/app-versions-widget/app-versions-widget';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';
import { ListName, getFetchListTrackName, listsActionCreators } from '../../../../logic/store/lists/lists.action';
import { getListData } from '../../../../logic/store/lists/lists.selectors';
import { getInProgress } from '../../../../logic/store/process-tracker/process-tracker.selectors';
import { getDefaultSelectOptions } from '../../../components/form-inputs/select-option/select-option';
import { userActionCreators } from '../../../../logic/store/user/user.action';
import TimePeriodDropdown, {
  IsLastDaySelected,
} from '../../../components/app-versions-widget/components/time-period-dropdown/time-period-dropdown';

const SAAppversionsWidget: React.FC = () => {
  const dispatch = useDispatch();
  const [selectedCompany, setSelectedCompany] = useState<{ companyId: number; companyName: string }>();
  const [selectedTimePeriod, setSelectedTimePeriod] = useState<number>(7);

  const companies = useSelector(getListData(ListName.company)) || [];
  const companiesFetchInProgress = useSelector(getInProgress(getFetchListTrackName(ListName.company)));
  const companiesOptions = getDefaultSelectOptions(companies);

  useEffect(() => {
    dispatch(listsActionCreators.fetchList(ListName.company, undefined, 250));
  }, [dispatch]);

  useEffect(() => {
    dispatch(userActionCreators.fetchAppVersionsAnalytics(selectedTimePeriod, selectedCompany?.companyId));
  }, [dispatch, selectedCompany?.companyId, selectedTimePeriod]);

  return (
    <>
      <Row style={{ justifyContent: 'end', paddingBottom: 10 }}>
        <TimePeriodDropdown onChange={(value) => setSelectedTimePeriod(value)} />
        <StyledSpace>
          <BuildOutlined style={{ fontSize: 24, marginLeft: 10, marginTop: 4 }} />
          <Select
            style={{ width: 175 }}
            options={companiesOptions}
            filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input.toLowerCase())}
            onSelect={(value, option) => {
              setSelectedCompany({ companyId: value, companyName: option.label ?? '' });
            }}
            onClear={() => setSelectedCompany(undefined)}
            loading={companiesFetchInProgress}
            placeholder={translate(translationKeys.pages.dashboard.appVersions.saCompanyPlaceholder)}
            allowClear
            showSearch
          />
        </StyledSpace>
      </Row>
      <AppVersionsWidget
        title={translate(
          IsLastDaySelected(selectedTimePeriod)
            ? translationKeys.pages.dashboard.appVersions.titleOneDay
            : translationKeys.pages.dashboard.appVersions.titleManyDays,
          {
            companyTitle:
              selectedCompany?.companyName ??
              translate(translationKeys.pages.dashboard.appVersions.saCompanyPlaceholder).toLocaleLowerCase(),
            appVersionsDays: selectedTimePeriod,
          }
        )}
        showOutdatedList={selectedCompany?.companyId !== undefined}
        timePeriod={`${selectedTimePeriod}`}
      />
    </>
  );
};

const StyledSpace = styled(Space)`
  padding-top: 5px;
`;

StyledSpace.defaultProps = {
  size: [10, 5],
  wrap: true,
};

export default SAAppversionsWidget;
