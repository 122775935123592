import React, { useCallback, useEffect } from 'react';
import { MasterContent } from '../master-page/components/master-content/master-content';
import { MasterPageHeader } from '../components/master-page-header/master-page-header';
import { Collapse, Row, Col, Button } from 'antd';
import { translate, translationKeys } from '../../logic/translations/translations.service';
import { Resource } from '../components/resources/resource.const';
import parse from 'html-react-parser';
import styled from 'styled-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  getAuthenticatedUser,
  getAuthenticatedUserCompany,
  getIsInitialSetupDone,
} from '../../logic/store/authentication/authentication.selectors';
import { SetupCompanyButton } from './setup-company/setup-company-button/setup-company-button';
import { shouldShowGettingStarted, shouldShowLicenseCount } from './dashboard.logic';
import { UserOutlined } from '@ant-design/icons';
import { colors, featureColor } from '../../styles/colors';
import Title from 'antd/lib/typography/Title';
import { useQrCodeModal } from '../components/qr-code-modal/qr-code-modal';
import { QR_CODE_APPLE_WATCH, QR_CODE_APP_STORE, QR_CODE_GOOGLE_PLAY } from '../../assets/icons';
import { navigationActionCreators } from '../../logic/store/navigation/navigation.actions';
import { AUTHENTICATED_ROUTES } from '../../logic/routes/routes.const';
import { APPLICATION_PADDING } from '../../styles/margins';
import { VideoSection } from '../components/video-section/video-section';
import hardwareBanner from '../../assets/banners/affordable_hardware.jpg';
import GreetingsModal from '../components/greetings-modal/greetings-modal';
import { authenticationActionCreators } from '../../logic/store/authentication/authentication.actions';
import Installers from '../components/installers/installers';
import { getNewestResourceConfigs } from './dashboard.const';

declare global {
  interface Window {
    dataLayer: any[];
  }
}

const { Panel } = Collapse;

export const DashboardPage: React.FC = () => {
  const dispatch = useDispatch();
  const company = useSelector(getAuthenticatedUserCompany);
  const authenticatedUser = useSelector(getAuthenticatedUser);
  const shouldShowCompleteSetup = useSelector(getIsInitialSetupDone) === false;

  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'setupDashboardTab_view',
    });

    // refresh user company to see new locations limit
    dispatch(authenticationActionCreators.refreshUserCompany());
  }, [dispatch]);

  const layout = {
    md: 24,
    lg: 12,
  };
  const androidQrCodeModal = useQrCodeModal({
    title: translate(translationKeys.pages.dashboard.installers.modals.android.title),
    qrCodeUrl: QR_CODE_GOOGLE_PLAY,
  });
  const iOSQrCodeModal = useQrCodeModal({
    title: translate(translationKeys.pages.dashboard.installers.modals.iOS.title),
    qrCodeUrl: QR_CODE_APP_STORE,
  });
  const appleWatchQrCodeModal = useQrCodeModal({
    title: translate(translationKeys.pages.dashboard.installers.modals.appleWath.title),
    qrCodeUrl: QR_CODE_APPLE_WATCH,
  });

  const renderParagraph = (contentKey: string) => (
    <div className={'text-justified'}>{parse(translate(contentKey))}</div>
  );

  const handleSupport = useCallback(
    () => dispatch(navigationActionCreators.navigateTo(AUTHENTICATED_ROUTES.Support)),
    [dispatch]
  );

  const resourceConfigs = getNewestResourceConfigs(
    androidQrCodeModal.show,
    iOSQrCodeModal.show,
    appleWatchQrCodeModal.show
  );

  const renderGettingStarted = () => (
    <Collapse defaultActiveKey={['1', '2']}>
      {shouldShowCompleteSetup && (
        <Panel showArrow={true} header={translate(translationKeys.pages.dashboard.setUpAutomatically.title)} key="1">
          <CenteredContent>
            <VideoSectionDashboard res={Resource.dashboardVideo} />
          </CenteredContent>
          <CompleteSetupVideoTitle>
            {renderParagraph(translationKeys.pages.dashboard.setUpAutomatically.content.videoTitle)}
          </CompleteSetupVideoTitle>
          {renderParagraph(translationKeys.pages.dashboard.setUpAutomatically.content.step1)}
          <SetupButtonContainer>
            <CenteredHeading>
              {renderParagraph(translationKeys.pages.dashboard.setUpAutomatically.content.setUpButton.heading)}
            </CenteredHeading>
            <SetupCompanyButton />
          </SetupButtonContainer>
          {renderParagraph(translationKeys.pages.dashboard.setUpAutomatically.content.step2)}
        </Panel>
      )}
      <Panel showArrow={true} header={translate(translationKeys.pages.dashboard.setUpManually.title)} key="2">
        <div className={'text-justified'}>
          {parse(translate(translationKeys.pages.dashboard.setUpManually.content))}
        </div>
      </Panel>
    </Collapse>
  );

  const salesEmail = 'sales@teamalert.com';

  const renderUserLicenseCount = () => (
    <Collapse activeKey={['1']}>
      <Panel showArrow={false} header={translate(translationKeys.pages.dashboard.licenseCount.title)} key="1">
        {translate(translationKeys.pages.dashboard.licenseCount.usersLimit, { usersLimit: company?.max_users_no })}
        <br />
        {translate(translationKeys.pages.dashboard.licenseCount.activeUsers, { activeUsers: company?.users_no })}
        <br />
        <br />
        {translate(translationKeys.pages.dashboard.licenseCount.locationsLimit, {
          locationsLimit: company?.max_locations_no,
        })}
        <br />
        {translate(translationKeys.pages.dashboard.licenseCount.activeLocations, {
          activeLocations: company?.locations_no,
        })}
        <br />
        <br />
        {translate(translationKeys.pages.dashboard.licenseCount.contactUs)}
        <a href={'mailto:' + salesEmail}>{salesEmail}</a>
      </Panel>
    </Collapse>
  );

  const renderRecommendation = () => (
    <Collapse activeKey={['1']}>
      <Panel showArrow={false} header={translate(translationKeys.pages.dashboard.recommendation.title)} key="1">
        {translate(translationKeys.pages.dashboard.recommendation.content)}
      </Panel>
    </Collapse>
  );

  return (
    <>
      <MasterPageHeader
        showAd
        content={
          shouldShowGettingStarted(authenticatedUser) ? (
            <HeaderContainer>
              <UserOutlined style={{ fontSize: '50px', color: colors.curiousBlue }} />
              <Title className={'text-centered'} level={4}>
                {translate(translationKeys.pages.dashboard.header.title1)}
                <TitleColored color={colors.curiousBlue}>
                  {translate(translationKeys.pages.dashboard.header.title2)}
                </TitleColored>
                <TitleColored color={featureColor.secondary}>
                  {translate(translationKeys.pages.dashboard.header.title3)}
                </TitleColored>
                {translate(translationKeys.pages.dashboard.header.title4)}
              </Title>
              <HeaderDescription>
                {translate(translationKeys.pages.dashboard.header.description1)}
                <ClickHereButton type="link" onClick={handleSupport}>
                  {translate(translationKeys.pages.dashboard.header.description2)}
                </ClickHereButton>
                {translate(translationKeys.pages.dashboard.header.description3)}
              </HeaderDescription>
            </HeaderContainer>
          ) : null
        }
      />
      <MasterContent>
        <Row gutter={[16, 16]}>
          {shouldShowGettingStarted(authenticatedUser) ? (
            <Col {...layout}>
              <Row gutter={[16, 16]}>
                <Col>{renderGettingStarted()}</Col>
              </Row>
            </Col>
          ) : null}
          <Col {...layout}>
            <Row gutter={[16, 16]}>
              <Col>
                <Installers
                  keyTitle={'currentInstallers'}
                  headerTitle={translate(translationKeys.pages.dashboard.installers.title)}
                  subTitle={translate(translationKeys.pages.dashboard.installers.subTitle)}
                  resourceConfigs={resourceConfigs}
                />
              </Col>
              {shouldShowLicenseCount(authenticatedUser) ? <Col>{renderUserLicenseCount()}</Col> : null}
              <Col>{renderRecommendation()}</Col>
              <Col>
                <a href={'https://teamalert.com/pricing/#add-ons'} target={'__blank'}>
                  <AdBanner src={hardwareBanner} alt={''} />
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
        {androidQrCodeModal.render()}
        {iOSQrCodeModal.render()}
        {appleWatchQrCodeModal.render()}
        <GreetingsModal />
      </MasterContent>
    </>
  );
};

const AdBanner = styled.img`
  width: 100%;
`;

const SetupButtonContainer = styled.div`
  margin-bottom: 15px;
  text-align: center;
`;

const HeaderContainer = styled.div`
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const TitleColored = styled.span<{ color: string }>`
  color: ${({ color }) => color};
  font-weight: 800;
`;

const HeaderDescription = styled.div`
  max-width: 620px;
`;

const ClickHereButton = styled(Button)`
  padding: 0;
`;

const VideoSectionDashboard = styled(VideoSection)`
  max-width: 400px;
`;

const CenteredContent = styled.div`
  padding: ${APPLICATION_PADDING / 2}px;
  display: flex;
  justify-content: center;
`;

const CompleteSetupVideoTitle = styled.div`
  margin-bottom: ${APPLICATION_PADDING * 2}px;
  font-size: 15px;
  justify-content: center;
  display: flex;
`;

const CenteredHeading = styled.div`
  margin-bottom: ${APPLICATION_PADDING}px;
  font-size: 17px;
  font-weight: 600;
  justify-content: center;
  display: flex;
`;
