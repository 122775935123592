import React from 'react';
import { Layout, Menu } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { navigationActionCreators } from '../../../../logic/store/navigation/navigation.actions';
import { useLocation } from 'react-router-dom';
import { getMenuItems, getSelectedMenuItems, getMenuPaths } from './side-menu.logic';
import { getAuthenticatedUser } from '../../../../logic/store/authentication/authentication.selectors';
import './side-menu.styles.scss';
import { SideMenuItem } from './side-menu.const';
import { MenuProps } from 'antd/lib/menu';
import { useMediaQuery } from 'react-responsive';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import menuBanner from '../../../../assets/banners/team_alert_mobile.jpg';

const { Sider } = Layout;

export const SideMenu: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const authenticatedUser = useSelector(getAuthenticatedUser);
  const isMobile = useMediaQuery({ maxWidth: 991 });

  const handleMenuItemClick: MenuProps['onClick'] = ({ key }) =>
    dispatch(navigationActionCreators.navigateTo(`${key}`));

  const menuItems = getMenuItems(authenticatedUser || undefined);
  const selectedMenuItemKeys = getSelectedMenuItems(menuItems, `${location.pathname}${location.search}`);
  const allMenuItemsKeys = getMenuPaths(menuItems);

  const renderMenuItems = (items: SideMenuItem[]): Array<ItemType> =>
    items.map((item) =>
      item.title
        ? {
            label: item.title ?? '',
            key: item.key ?? '',
            icon: item.icon,
            children: item.subItems?.length ? renderMenuItems(item.subItems) : undefined,
          }
        : null
    );

  const defaultOpenMenuKeys = allMenuItemsKeys.filter((x) => !x.includes('sso'));

  return (
    <Sider width={200} collapsedWidth={50} collapsed={isMobile}>
      <Menu
        theme={'light'}
        mode={'inline'}
        selectedKeys={selectedMenuItemKeys}
        onClick={handleMenuItemClick}
        defaultOpenKeys={!isMobile ? defaultOpenMenuKeys : []}
        className={'side-menu'}
        items={renderMenuItems(menuItems)}
      />
      <a href={'https://teamalert.com/teamalert-mobile'} target={'__blank'}>
        <img src={menuBanner} className={'side-menu__banner'} alt={''} />
      </a>
    </Sider>
  );
};
