import { Row, Col } from 'antd';
import parse from 'html-react-parser';

import { MasterPageHeader } from '../components/master-page-header/master-page-header';
import { MasterContent } from '../master-page/components/master-content/master-content';
import AppVersionsWidget from '../components/app-versions-widget/app-versions-widget';
import { PageDescription } from '../components/page-description/page-description';
import { translate, translationKeys } from '../../logic/translations/translations.service';
import { useCallback, useEffect, useState } from 'react';
import { userActionCreators } from '../../logic/store/user/user.action';
import { useDispatch } from 'react-redux';
import TimePeriodDropdown, {
  IsLastDaySelected,
} from '../components/app-versions-widget/components/time-period-dropdown/time-period-dropdown';

const ReportsPage = () => {
  const dispatch = useDispatch();
  const [selectedTimePeriod, setSelectedTimePeriod] = useState<number>(7);

  const fetchAppVersionsAnalytics = useCallback(
    () => dispatch(userActionCreators.fetchAppVersionsAnalytics(selectedTimePeriod)),
    [dispatch, selectedTimePeriod]
  );

  const renderAppVersionWidget = () => (
    <>
      <Row style={{ justifyContent: 'end', paddingBottom: 10 }}>
        <TimePeriodDropdown onChange={(value) => setSelectedTimePeriod(value)} />
      </Row>
      <AppVersionsWidget
        title={translate(
          IsLastDaySelected(selectedTimePeriod)
            ? translationKeys.pages.dashboard.appVersions.titleOneDay
            : translationKeys.pages.dashboard.appVersions.titleManyDays,
          {
            companyTitle: translate(translationKeys.pages.dashboard.appVersions.caCompanyTitle),
            appVersionsDays: selectedTimePeriod,
          }
        )}
        showTextMessage
        showOutdatedList
        timePeriod={selectedTimePeriod.toString()}
      />
    </>
  );

  const layout = {
    md: 24,
    lg: 12,
  };

  useEffect(() => {
    fetchAppVersionsAnalytics();
  }, [fetchAppVersionsAnalytics]);

  return (
    <>
      <MasterPageHeader
        content={
          <PageDescription
            title={translate(translationKeys.pages.reports.title)}
            content={<p>{parse(translate(translationKeys.pages.reports.description))}</p>}
          />
        }
      />
      <MasterContent>
        <Row gutter={[16, 16]}>
          <Col {...layout}>{renderAppVersionWidget()}</Col>
        </Row>
      </MasterContent>
    </>
  );
};

export default ReportsPage;
