import { SupportContactFormFieldName } from '../pages/support/support.contact-form.field-name';

export interface Group extends Undefineable<GeneratedApiModels.Group> {}

export interface Location extends Undefineable<GeneratedApiModels.Location> {
  emergency_mode?: EmergencyMode;
}

export interface CompanySsoDomain extends Undefineable<GeneratedApiModels.CompanySSODomain> {}

export interface SsoCompanyAdmin extends Undefineable<GeneratedApiModels.SSOCompanyAdmin> {}

export interface SsoGroupMapping extends Undefineable<GeneratedApiModels.CompanySSOGroupMapping> {}

export interface SsoCompanyGroupMapping extends Undefineable<GeneratedApiModels.CompanySSOCompanyGroupMapping> {}

export interface AlertType extends Undefineable<GeneratedApiModels.AlertType> {}

export interface AlertFile extends Undefineable<GeneratedApiModels.AlertFile> {}

export interface AlertCustomResponse extends Undefineable<GeneratedApiModels.AlertCustomResponse> {}

export enum EmergencyMode {
  E911 = 1,
  E112 = 2,
  Monitoring = 3,
}

export enum Role {
  ViewOnly = 1,
  Regular = 2,
  GroupAdmin = 3,
  CompanyAdmin = 4,
  SuperAdmin = 5,
}

export enum AccountStatus {
  New = 1,
  InviteSent = 2,
  InviteFailed = 3,
  Active = 4,
  ChangeFailed = 5,
  NotActive = 6,
  SetPasswordFailed = 7,
}

export enum OsType {
  iOS = 1,
  MacOS = 2,
  WIN = 3,
  Android = 4,
  AppleWatch = 5,
}

export interface UserDevice extends Undefineable<GeneratedApiModels.UserDevices> {
  os_type?: OsType;
}

export interface User extends Undefineable<GeneratedApiModels.User> {
  permission_level?: Role;
  status?: AccountStatus;
  last_used_devices?: UserDevice[];
}

export interface LocationTag {
  id?: string;
  location_id: string;
  tag?: string;
  user_id: string;
  location?: Location;
}

export interface UserContactInfo extends Undefineable<GeneratedApiModels.UserContactInfo> {}

export interface Company extends Undefineable<GeneratedApiModels.Company> {}

export type Industry = string;

export interface Country extends Undefineable<GeneratedApiModels.Country> {}

export interface AlertIcon extends Undefineable<GeneratedApiModels.AlertIcon> {}

export interface Alert extends Undefineable<GeneratedApiModels.Alert> {
  emergency_mode?: EmergencyMode;
}

export interface UserAlertChannelSettings extends Undefineable<GeneratedApiModels.UserAlertChannelSettings> {}

export enum ImportUserResponseRowStatus {
  OK = 'OK',
  SKIPPED = 'SKIPPED',
  ERROR = 'ERROR',
}

export interface ImportUserData {
  first_name?: string;
  last_name?: string;
  email?: string;
  hb1_message?: string;
  hb2_message?: string;
  address?: string;
  city?: string;
  zip_code?: string;
  country?: string;
  department?: string;
  sms_phone?: string;
  voice_call_phone?: string;
  state?: string;
}

type ChangeFieldsType<T, N> = {
  [P in keyof T]: N;
};

export type ImportUserResponseRowError = ChangeFieldsType<ImportUserData, string[] | string>;

export type ImportUserResponseRow = {
  status?: ImportUserResponseRowStatus;
  data?: ImportUserResponseRowData;
  error?: ImportUserResponseRowError;
};

export type ImportUserResponseRowData =
  | string[]
  | {
      row?: string[];
      data?: string[];
    };

export type ImportUserFileStructureError = {
  status?: ImportUserResponseRowStatus;
  data?: {
    allowed_fields?: string[];
  };
  error?: {
    [x: string]: string[] | string | undefined;
  };
};

type AnalyticsSection = {
  [x: string]: string[] | undefined;
};

export enum AnalyticSectionName {
  summary = 'summary',
  city = 'city',
  zip_code = 'zip_code',
  state = 'state',
  country = 'country',
  industry = 'industry',
}

export interface Analytics {
  companies_active?: number;
  companies_non_trial?: number;
  companies_total?: number;
  companies_trial?: number;

  users_active?: number;
  users_non_trial?: number;
  users_total?: number;
  users_trial?: number;

  [AnalyticSectionName.city]?: AnalyticsSection;
  [AnalyticSectionName.zip_code]?: AnalyticsSection;
  [AnalyticSectionName.state]?: AnalyticsSection;
  [AnalyticSectionName.country]?: AnalyticsSection;
  [AnalyticSectionName.industry]?: AnalyticsSection;
}

export interface AppVersionUserData {
  first_name: string;
  last_name: string;
  email: string;
  os_type: OsType;
}

export interface AppVersionData {
  value: number;
  name: string;
  users: Array<AppVersionUserData>;
}

export interface AppVersionOs {
  data: Array<AppVersionData>;
}

export interface AppVersions {
  Android: AppVersionOs;
  AppleWatch: AppVersionOs;
  iOS: AppVersionOs;
  MacOS: AppVersionOs;
  WIN: AppVersionOs;
}

export enum UserBulkOperation {
  Delete = 1,
  Invite = 2,
  Reinvite = 3,
}

export interface UserBulkOperationResponse {
  results?: {
    user_id?: number;
    error?: string;
  }[];
}

export enum AutocompleteClass {
  Alert = 1,
  Group = 2,
  User = 3,
  Location = 4,
}

export interface AutocompleteResponseAlert {
  instructions?: string[];
  name?: string[];
  voice_message?: string[];
}

export interface AutocompleteResponseGroup {
  address?: string[];
  city?: string[];
  country?: string[];
  name?: string[];
  phone?: string[];
  state?: string[];
  zip_code?: string[];
}

export interface AutocompleteResponseUser {
  address?: string[];
  city?: string[];
  country?: string[];
  department?: string[];
  state?: string[];
  zip_code?: string[];
}

export type AutocompleteResponse = AutocompleteResponseAlert | AutocompleteResponseGroup | AutocompleteResponseUser;

export type SupportContactMapperType = { [p in Exclude<SupportContactFormFieldName, 'message'>]: string };
