export enum AlertFieldName {
  id = 'id',
  name = 'name',
  created_at = 'created_at',
  lowered_at = 'lowered_at',
  raising_user = 'raising_user',
  lowering_user = 'lowering_user',
  messages = 'messages',
  company_name = 'company_name',
  emergency_mode = 'emergency_mode',
}
