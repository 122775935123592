import { translationKeys } from '../../logic/translations/translations.service';
import { Resource, ResourceConfig } from '../components/resources/resource.const';

const BOLD_TEXT_CLASS = 'resource-link__text-bold';
const INSTALLER_REGULAR_USER_TOOLTIP = translationKeys.pages.dashboard.installers.regularUserTooltip;
const INSTALLER_ADMIN_USER_TOOLTIP = translationKeys.pages.dashboard.installers.adminUsersTooltip;

export const getPreviousResourceConfigs = (): ResourceConfig[] => [
  { resource: Resource.divider },
  {
    resource: Resource.desktopAppInstallerWindowsRegularUserPrevious,
    textClassName: BOLD_TEXT_CLASS,
    tooltipMessage: INSTALLER_REGULAR_USER_TOOLTIP,
  },
  {
    resource: Resource.desktopAppInstallerMacPrevious,
    textClassName: BOLD_TEXT_CLASS,
  },
  {
    resource: Resource.desktopAppInstallerWindowsDomainAdminUserPrevious,
    textClassName: BOLD_TEXT_CLASS,
    tooltipMessage: INSTALLER_ADMIN_USER_TOOLTIP,
  },
];
