import { reduce } from 'lodash';
import { SideMenuItem, getSideMenus } from './side-menu.const';
import { User } from '../../../../api-models/api-models';
import { matchPath } from 'react-router-dom';

export const getMenuItems = (user: User | undefined) =>
  (!!user?.permission_level ? getSideMenus(user)[user?.permission_level] : []) || [];

export const getMenuPaths = (menuItems: SideMenuItem[]): string[] =>
  reduce(
    menuItems,
    (accumulator, item) => [...accumulator, ...(item.subItems ? getMenuPaths(item.subItems) : [item.key ?? ''])],
    [] as string[]
  );

export const getSelectedMenuItems = (menuItems: SideMenuItem[], path: string) => {
  const exact = getMenuPaths(menuItems).find((menuPath) => matchPath(path, { path: menuPath }));
  const similar = getMenuPaths(menuItems).find((menuPath) => path.includes(menuPath));

  return exact ? [exact] : similar ? [similar] : [];
};
