import { Collapse, Divider } from 'antd';
import styled from 'styled-components';

import { ResourceLink, ResourcesRow } from '../resources/resource-link';
import { ResourceConfig, resourceContents, ResourceType } from '../resources/resource.const';
import { translate, translationKeys } from '../../../logic/translations/translations.service';

interface InstallersProps {
  headerTitle: string;
  resourceConfigs: ResourceConfig[];
  keyTitle: string;
  subTitle?: string;
}

const Installers: React.FC<InstallersProps> = ({ keyTitle, headerTitle, subTitle, resourceConfigs }) => {
  const { Panel } = Collapse;

  const renderResources = () => {
    return resourceConfigs.map(({ resource, textClassName, qrModalProps = [], tooltipMessage, notes }, index) =>
      resourceContents[resource].resourceType === ResourceType.Divider ? (
        <Divider key="Resource divider" style={{ marginTop: 10, marginBottom: 8 }} />
      ) : (
        <ResourcesRow key={translate(translationKeys.resources[resource])}>
          <ResourceLink
            content={resourceContents[resource]}
            text={translate(translationKeys.resources[resource])}
            textClassName={textClassName}
            tooltipMessage={tooltipMessage ? translate(tooltipMessage) : undefined}
            notes={notes ? translate(notes) : undefined}
          />
          {qrModalProps.map(({ resource, action, noPaddingLeft }) => (
            <ResourceLink
              key={resource}
              content={resourceContents[resource]}
              text={translate(translationKeys.resources[resource])}
              noPaddingLeft={noPaddingLeft}
              action={action}
            />
          ))}
        </ResourcesRow>
      )
    );
  };

  return (
    <Collapse activeKey={keyTitle}>
      <Panel key={keyTitle} showArrow={false} header={headerTitle}>
        <HeaderTitle style={{ marginBottom: 4 }}>{subTitle}</HeaderTitle>
        {renderResources()}
      </Panel>
    </Collapse>
  );
};

const HeaderTitle = styled.div`
  font-weight: 500;
`;

export default Installers;
