import { all, call, put, select, takeEvery, takeLeading } from 'redux-saga/effects';
import { userActionCreators, UserActions, UserActionTypes } from './user.action';
import { SagaManager } from '../../saga-manager/saga-manager';
import { userApi } from './user.api';
import { logActionCreators } from '../log/log.actions';
import { navigationActionCreators } from '../navigation/navigation.actions';
import { fillReduxForm } from '../../redux-form/fill-redux-form';
import { ReturnActionOfType } from '../../redux/action-creator';
import { translate, translationKeys } from '../../translations/translations.service';
import { tableActionCreators, TableName } from '../tables/tables.action';
import { mapServerErrorsToReduxFormErrors } from '../../server-error-parser/server-error-parser';
import { validateUserForm } from '../../../pages/users/pages/user-form-template/user-form.form';
import {
  Analytics,
  AppVersions,
  ImportUserResponseRow,
  ImportUserResponseRowStatus,
  LocationTag,
  Role,
  User,
  UserBulkOperationResponse,
} from '../../../api-models/api-models';
import { getAuthenticatedUser } from '../authentication/authentication.selectors';
import { change, getFormValues, reset, untouch } from 'redux-form';
import { pick, take } from 'lodash';
import { SupportContactForm } from '../../../pages/support/support.contact-form.form';
import {
  getFirstFailedUserName,
  getFirstSuccessfullUserName,
  getUniqueErrorMessages,
  getUserBulkOperationFailedCount,
  getUserBulkOperationSuccessfullCount,
  importUsersFileStructureErrorHandler,
  isEmaiExistsError,
} from './user.logic';
import { validateImportUsersForm } from '../../../pages/users/pages/import-users/import-users.form';
import { AxiosResponse } from 'axios';
import { ImportUsersResults } from './user.model';
import { progressHandler } from '../../axios/axios-progres-handler';
import { ImportUsersFormFieldName } from '../../../pages/users/pages/import-users/import-users.field-name';
import fileSaver from '../../utils/file-saver';
import { validateSetNewPasswordForm } from '../../../pages/users/pages/components/set-new-password/set-new-password-form/set-new-password.form';
import { locationTagApi } from '../location-tag/location-tag.api';
import { ListName, listsActionCreators } from '../lists/lists.action';
import { ApiResponse } from '../../axios/axios-api-response';
import { authenticationActionCreators } from '../authentication/authentication.actions';
import { getLocationTagToAdd, getLocationTagToDelete, getLocationTagToUpdate } from '../../user/user.helper';
import { getListData } from '../lists/lists.selectors';
import { getEqualOrHigherRoles } from '../../../pages/authentication/models/role/role.utils';
import { isAuthorized } from '../../routes/authorized-route/authorized-route.component';
import { GetLocationTagParameters } from '../location-tag/location-tag.models';

export function* userSagaWatch() {
  yield takeLeading(UserActionTypes.ADD_SINGLE, addSingleSagaManaged);
  yield takeLeading(UserActionTypes.UPDATE_SINGLE, updateSingleSagaManaged);
  yield takeLeading(UserActionTypes.FETCH_SINGLE_FOR_EDIT, fetchSingleForEditSagaManaged);
  yield takeEvery(UserActionTypes.INITIALIZE_SUPPORT_FORM, initializeSupportFormSaga);
  yield takeLeading(UserActionTypes.SUBMIT_SUPPORT_FORM, submitSupportFormSageManaged);
  yield takeLeading(UserActionTypes.IMPORT_USERS, importUsersSagaManaged);
  yield takeEvery(UserActionTypes.FETCH_ANALYTICS, fetchAnalyticsSagaManaged);
  yield takeEvery(UserActionTypes.FETCH_APP_VERSIONS_ANALYTICS, fetchAppVersionsAnalyticsSagaManaged);
  yield takeEvery(UserActionTypes.SET_PHOTO, setUserPhotoSaga);
  yield takeEvery(UserActionTypes.DELETE_PHOTO, deleteUserPhotoSaga);
  yield takeLeading(UserActionTypes.DOWNLOAD_CSV, downloadCsvSagaManaged);
  yield takeLeading(UserActionTypes.PERFORM_USERS_BULK_OPERATION, performUsersBulkOperationManaged);
  yield takeLeading(UserActionTypes.SET_NEW_PASSWORD, setNewPasswordSagaManaged);
  yield takeEvery(UserActionTypes.WELCOME_POPUP_DONE, setWelcomePopupDoneSaga);
}

function* setNewPasswordSagaManaged(action: ReturnActionOfType<UserActions, UserActionTypes.SET_NEW_PASSWORD>) {
  yield new SagaManager()
    .addTracking(userActionCreators.setNewPassword.name)
    .addReduxFormValidation(action.payload.formName, action.payload.formValues, validateSetNewPasswordForm)
    .addReduxFormAsyncValidation(action.payload.formName, mapServerErrorsToReduxFormErrors)
    .execute(setNewPasswordSaga, action);
}

function* setNewPasswordSaga(action: ReturnActionOfType<UserActions, UserActionTypes.SET_NEW_PASSWORD>) {
  const { userId, userName = '' } = action.payload;
  const newPassword = action.payload.formValues.newPassword;

  if (userId && newPassword) {
    yield call(userApi.setNewPassword, userId, newPassword);
    yield put(
      logActionCreators.logSuccess(translate(translationKeys.messages.newPasswordWasSuccessfullySet, { userName }))
    );
  }
}

function* setWelcomePopupDoneSaga(action: ReturnActionOfType<UserActions, UserActionTypes.WELCOME_POPUP_DONE>) {
  const { userId } = action.payload;

  if (userId) {
    yield call(userApi.setWelcomePopupDone, userId);
  }
}

function* performUsersBulkOperationManaged(
  action: ReturnActionOfType<UserActions, UserActionTypes.PERFORM_USERS_BULK_OPERATION>
) {
  yield new SagaManager()
    .addTracking(userActionCreators.performUsersBulkOperation.name)
    .execute(performUsersBulkOperation, action);
}

function* performUsersBulkOperation(
  action: ReturnActionOfType<UserActions, UserActionTypes.PERFORM_USERS_BULK_OPERATION>
) {
  const users = action.payload.users;
  // const userIds = users?.map((user, index) => (index < 2 ? 0 : user?.id)) || [];
  const userIds = users?.map((user) => user?.id) || [];
  const bulkOperation = action.payload.bulkOperation;

  if (userIds.length) {
    const response: AxiosResponse<UserBulkOperationResponse> = yield call(
      userApi.performBulkAction,
      userIds,
      bulkOperation
    );
    yield put(tableActionCreators.fetchTable(TableName.user));
    const successfullOperationCount = getUserBulkOperationSuccessfullCount(response.data);
    const failedOperationCount = getUserBulkOperationFailedCount(response.data);
    const successfullUserName = getFirstSuccessfullUserName(response.data, users);
    const failedUserName = getFirstFailedUserName(response.data, users);
    const errorMessage = getUniqueErrorMessages(response.data);

    if (successfullOperationCount === 1) {
      yield put(
        logActionCreators.logSuccess(
          translate(translationKeys.messages.userOperationSussess[bulkOperation], { userName: successfullUserName })
        )
      );
    }
    if (successfullOperationCount > 1) {
      yield put(
        logActionCreators.logSuccess(
          translate(translationKeys.messages.usersBulkOperationSussess[bulkOperation], {
            count: successfullOperationCount,
          })
        )
      );
    }

    if (failedOperationCount === 1) {
      yield put(
        logActionCreators.logError(
          translate(translationKeys.messages.userOperationFailure[bulkOperation], {
            userName: failedUserName,
            errorMessage,
          })
        )
      );
    }
    if (failedOperationCount > 1) {
      yield put(
        logActionCreators.logError(
          translate(translationKeys.messages.usersBulkOperationFailure[bulkOperation], {
            count: failedOperationCount,
            errorMessage,
          })
        )
      );
    }
  }
}

function* fetchAnalyticsSagaManaged(action: ReturnActionOfType<UserActions, UserActionTypes.FETCH_ANALYTICS>) {
  yield new SagaManager().addTracking(userActionCreators.fetchAnalytics.name).execute(fetchAnalyticsSaga, action);
}

function* fetchAnalyticsSaga(action: ReturnActionOfType<UserActions, UserActionTypes.FETCH_ANALYTICS>) {
  const analytics: AxiosResponse<Analytics> = yield call(userApi.fetchAnalytics);

  yield put(userActionCreators.setAnalytics(analytics));
}

function* fetchAppVersionsAnalyticsSagaManaged(
  action: ReturnActionOfType<UserActions, UserActionTypes.FETCH_APP_VERSIONS_ANALYTICS>
) {
  yield new SagaManager()
    .addTracking(userActionCreators.fetchAppVersionsAnalytics.name)
    .execute(fetchAppVersionsAnalyticsSaga, action);
}

function* fetchAppVersionsAnalyticsSaga(
  action: ReturnActionOfType<UserActions, UserActionTypes.FETCH_APP_VERSIONS_ANALYTICS>
) {
  const { period, company } = action.payload;
  const appVersions: AxiosResponse<AppVersions> = yield call(userApi.fetchAppVersionsAnalytics, period, company);
  yield put(userActionCreators.setAppVersionsAnalytics(appVersions));
}

function* importUsersSagaManaged(action: ReturnActionOfType<UserActions, UserActionTypes.IMPORT_USERS>) {
  yield new SagaManager()
    .addTracking(userActionCreators.importUsers.name)
    .addReduxFormValidation(action.payload.formName, action.payload.formValues, validateImportUsersForm)
    .addCustomErrorHandler(importUsersFileStructureErrorHandler, action.payload.formName)
    .addReduxFormAsyncValidation(action.payload.formName, mapServerErrorsToReduxFormErrors)
    .execute(importUsersSaga, action);
}

const MAX_IMPORTED_ROWS_IN_RESULT = 100;

function* importUsersSaga(action: ReturnActionOfType<UserActions, UserActionTypes.IMPORT_USERS>) {
  yield put(userActionCreators.setImportUsersResult(undefined));

  const response: AxiosResponse<ImportUserResponseRow[]> = yield call(
    userApi.importUsers,
    action.payload.formValues,
    progressHandler(userActionCreators.importUsers.name),
    progressHandler(userActionCreators.importUsers.name)
  );

  const responseRows = response.data.map((row, index) => ({ ...row, index }));

  const imported = responseRows.filter((result) => result.status === ImportUserResponseRowStatus.OK);
  const skipped = responseRows.filter(
    (result) => result.status === ImportUserResponseRowStatus.SKIPPED && isEmaiExistsError(result.error)
  );
  const errored = responseRows.filter(
    (result) => result.status === ImportUserResponseRowStatus.SKIPPED && !isEmaiExistsError(result.error)
  );

  const importUsersResult: ImportUsersResults = {
    [ImportUserResponseRowStatus.OK]: {
      total: imported.length,
      rows: take(imported, MAX_IMPORTED_ROWS_IN_RESULT),
    },
    [ImportUserResponseRowStatus.SKIPPED]: {
      total: skipped.length,
      rows: take(skipped, MAX_IMPORTED_ROWS_IN_RESULT),
    },
    [ImportUserResponseRowStatus.ERROR]: {
      total: errored.length,
      rows: take(errored, MAX_IMPORTED_ROWS_IN_RESULT),
    },
  };

  yield put(userActionCreators.setImportUsersResult(importUsersResult));
  yield put(change(action.payload.formName, ImportUsersFormFieldName.file, null));
  yield put(untouch(action.payload.formName, ImportUsersFormFieldName.file));
}

function* submitSupportFormSageManaged(action: ReturnActionOfType<UserActions, UserActionTypes.SUBMIT_SUPPORT_FORM>) {
  yield new SagaManager()
    .addTracking(userActionCreators.submitSupportForm.name)
    .addReduxFormAsyncValidation(action.payload.formName, mapServerErrorsToReduxFormErrors)
    .execute(submitSupportFormSage, action);
}

function* submitSupportFormSage(action: ReturnActionOfType<UserActions, UserActionTypes.SUBMIT_SUPPORT_FORM>) {
  const user: User | undefined = yield select(getAuthenticatedUser);
  const { formName } = action.payload;
  const formValues: SupportContactForm = yield select(getFormValues(formName));
  const requestPayload = pick(formValues, ['message', 'company', 'phone']);

  if (user) {
    yield call(userApi.requestSuport, user, requestPayload);
    yield put(logActionCreators.logSuccess(translate(translationKeys.messages.yourSupportRequestWasSentSuccessfully)));
    yield put(reset(formName));
    yield put(userActionCreators.initializeSupportForm(formName, action.payload.mapper));
  }
}

function* initializeSupportFormSaga(action: ReturnActionOfType<UserActions, UserActionTypes.INITIALIZE_SUPPORT_FORM>) {
  const user: User | undefined = yield select(getAuthenticatedUser);
  yield fillReduxForm(action.payload.formName, action.payload.mapper, user);
}

export function* activateUserSagaManaged(user: User) {
  yield new SagaManager()
    .addCustomErrorMessage(translate(translationKeys.errors.unableToActivateUser))
    .execute(activateUserSaga, user);
}

export function* activateUserSaga(user: User) {
  yield call(userApi.activateUser, user);
}

function* fetchSingleForEditSagaManaged(
  action: ReturnActionOfType<UserActions, UserActionTypes.FETCH_SINGLE_FOR_EDIT>
) {
  yield new SagaManager()
    .addTracking(userActionCreators.fetchSingleForEdit.name)
    .execute(fetchSingleForEditSaga, action);
}

function* fetchSingleForEditSaga(action: ReturnActionOfType<UserActions, UserActionTypes.FETCH_SINGLE_FOR_EDIT>) {
  const { id } = action.payload;
  const authenticatedUser: User | undefined = yield select(getAuthenticatedUser);
  const isPermissionToLoadLocationTag = authenticatedUser
    ? isAuthorized(getEqualOrHigherRoles(Role.GroupAdmin), authenticatedUser?.permission_level)
    : false;

  let userTagLocation: LocationTag[] = [];

  const getLocationTagParameters: GetLocationTagParameters = { user_id: `${id}` };

  if (isPermissionToLoadLocationTag) {
    const { data } = yield call(locationTagApi.getSingleForEdit, getLocationTagParameters);
    userTagLocation = data.results;
  }

  const { data: responseUserData } = yield call(userApi.fetchSingle, id);
  const data = {
    user: responseUserData,
    locationTag: userTagLocation,
  };

  yield put(listsActionCreators.setData(ListName.editUserLocationTag, userTagLocation));
  yield fillReduxForm(action.payload.formName, action.payload.mapper, data);
}

function* updateSingleSagaManaged(action: ReturnActionOfType<UserActions, UserActionTypes.UPDATE_SINGLE>) {
  yield new SagaManager()
    .addTracking(userActionCreators.updateSingle.name)
    .addReduxFormValidation(action.payload.formName, action.payload.formValues, validateUserForm)
    .addReduxFormAsyncValidation(action.payload.formName, mapServerErrorsToReduxFormErrors)
    .execute(updateSingleSaga, action);
}

function* updateSingleSaga(action: ReturnActionOfType<UserActions, UserActionTypes.UPDATE_SINGLE>) {
  const userId = `${action.payload.formValues.id}` || '';
  const savedLocationTag = yield select(getListData(ListName.editUserLocationTag)) || [];
  const locationTagForm = action.payload.formValues.location_tag || [];

  const locationTagFormUpdate = getLocationTagToUpdate(locationTagForm);
  const locationTagFormAdd = getLocationTagToAdd(locationTagForm);
  const locationTagFormDelete = getLocationTagToDelete(locationTagForm, savedLocationTag);

  const fetchLocationTagFormUpdate = (locationTagFormUpdate || []).map((locationTag) =>
    call(locationTagApi.updateSingle, userId, locationTag)
  );

  const fetchLocationTagFormDelete = (locationTagFormDelete || []).map(({ id }) =>
    call(locationTagApi.deleteSingle, id || '')
  );

  const fetchLocationTagFormAdd = (locationTagFormAdd || []).map(({ location_id, tag_name }) =>
    call(locationTagApi.addSingle, userId, { location_id, tag_name })
  );

  yield all([...fetchLocationTagFormUpdate, ...fetchLocationTagFormDelete, ...fetchLocationTagFormAdd]);

  const responseFetchUpdateUser: ApiResponse<User> = yield call(userApi.updateSingle, action.payload.formValues);
  const user = responseFetchUpdateUser.data as User;
  const authenticatedUser: User | undefined = yield select(getAuthenticatedUser);

  if (action.payload.successMessage) {
    yield put(logActionCreators.logSuccess(action.payload.successMessage));
  }

  if (user && user.id === authenticatedUser?.id) {
    yield put(authenticationActionCreators.setAuthenticatedUser(user));
  }

  if (action.payload.successRedirectRoute) {
    yield put(navigationActionCreators.navigateTo(action.payload.successRedirectRoute));
  }
}

export function* updateSetupUserDetails(action: ReturnActionOfType<UserActions, UserActionTypes.UPDATE_SINGLE>) {
  yield updateSingleSaga(action);
}

function* addSingleSagaManaged(action: ReturnActionOfType<UserActions, UserActionTypes.ADD_SINGLE>) {
  yield new SagaManager()
    .addTracking(userActionCreators.addSingle.name)
    .addReduxFormValidation(action.payload.formName, action.payload.formValues, validateUserForm)
    .addReduxFormAsyncValidation(action.payload.formName, mapServerErrorsToReduxFormErrors)
    .execute(addSingleSaga, action);
}

function* addSingleSaga(action: ReturnActionOfType<UserActions, UserActionTypes.ADD_SINGLE>) {
  const resp = yield call(userApi.addSingle, action.payload.formValues);
  const userId = resp.data.id;
  const locationTag = getLocationTagToAdd(action.payload.formValues.location_tag);

  yield all(
    (locationTag || []).map(({ location_id, tag_name }) =>
      call(locationTagApi.addSingle, userId, { location_id, tag_name })
    )
  );
  yield put(logActionCreators.logSuccess(action.payload.successMessage));
  yield put(navigationActionCreators.navigateTo(action.payload.successRedirectRoute));
}

function* setUserPhotoSaga(action: ReturnActionOfType<UserActions, UserActionTypes.SET_PHOTO>) {
  yield call(userApi.setPhoto, action.payload.id, action.payload.photo);
}

function* deleteUserPhotoSaga(action: ReturnActionOfType<UserActions, UserActionTypes.DELETE_PHOTO>) {
  yield call(userApi.deletePhoto, action.payload.id);
}

function* downloadCsvSagaManaged(action: ReturnActionOfType<UserActions, UserActionTypes.DOWNLOAD_CSV>) {
  yield new SagaManager().addTracking(userActionCreators.downloadCsv.name).execute(downloadCsvSaga, action);
}

function* downloadCsvSaga(action: ReturnActionOfType<UserActions, UserActionTypes.DOWNLOAD_CSV>) {
  const response: AxiosResponse<any> = yield call(userApi.downloadCsv);
  const blob = new Blob([response.data]);
  yield fileSaver(blob, 'users.csv');
}
