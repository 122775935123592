import React, { useEffect } from 'react';
import { MasterContent } from '../master-page/components/master-content/master-content';
import { MasterPageHeader } from '../components/master-page-header/master-page-header';
import { Collapse, Row, Col } from 'antd';
import { map } from 'lodash';
import { useDispatch } from 'react-redux';
import { SupportContactForm } from './support.contact-form.fields';
import { translate, translationKeys } from '../../logic/translations/translations.service';
import { userActionCreators } from '../../logic/store/user/user.action';
import { SUPPORT_CONTACT_FORM } from './support.contact-form.form';
import { ResourceLinks } from '../components/resources/resource-link';
import { Resource } from '../components/resources/resource.const';
import parse from 'html-react-parser';
import './support.styles.scss';
import { mapUserDataToContactSupportForm } from '../users/pages/user-form-template/user-form.form';
import { VideoSection } from '../components/video-section/video-section';
import { PageDescription } from '../components/page-description/page-description';
import { useLocation } from 'react-router-dom';
import Installers from '../components/installers/installers';
import { getPreviousResourceConfigs } from './support.const';

const { Panel } = Collapse;

export const SupportPage: React.FC = (): JSX.Element => {
  const dispatch = useDispatch();
  const { hash } = useLocation();

  useEffect(() => {
    setTimeout(() => {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      element?.scrollIntoView();
    }, 0);
  }, [hash]);

  useEffect(() => {
    dispatch(userActionCreators.initializeSupportForm(SUPPORT_CONTACT_FORM, mapUserDataToContactSupportForm));
  }, [dispatch]);

  const resourceConfigs = getPreviousResourceConfigs();

  const renderSupportDocumentsSection = () => (
    <Collapse activeKey={['1']}>
      <Panel showArrow={false} header={translate(translationKeys.pages.support.supportDocuments.title)} key={'1'}>
        <Row>
          <Col {...layout}>
            <ResourceLinks
              resources={[
                Resource.crsConnect,
                Resource.welcomeToTeamAlert,
                Resource.userGuide,
                Resource.crsNotifyQuickStartGuide,
                Resource.usageRecommendations,
                Resource.quickSetupGuide,
              ]}
            />
          </Col>
          <Col {...layout}>
            <ResourceLinks
              resources={[
                Resource.testingInstructions,
                Resource.policyTemplate,
                Resource.endUserLicenseAgreement,
                Resource.termsOfUse,
                Resource.privacyPolicy,
              ]}
            />
          </Col>
        </Row>
      </Panel>
    </Collapse>
  );

  const renderSupportVideosSection = () => (
    <Collapse activeKey={['1']}>
      <Panel showArrow={false} header={translate(translationKeys.pages.support.supportVideos.title)} key={'1'}>
        <VideoSection res={Resource.teamAlertAcademy} showTitle />
        <VideoSection res={Resource.groupManagementVideo} showTitle />
        <VideoSection res={Resource.alertManagementVideo} showTitle />
        <VideoSection res={Resource.userManagementVideo} showTitle />
        <VideoSection res={Resource.channelManagementVideo} showTitle />
        <VideoSection res={Resource.locationsManagmentVideo} showTitle />
        <VideoSection res={Resource.msiTestVideo} showTitle />
      </Panel>
    </Collapse>
  );

  const renderFAQ = () => (
    <Collapse activeKey={['1']}>
      <Panel showArrow={false} header={translate(translationKeys.pages.support.faq.title)} key={'1'}>
        <Collapse>
          {map(translationKeys.pages.support.faq.content, (faq) => (
            <Panel header={translate(faq.question)} key={faq.question}>
              {parse(translate(faq.answer))}
            </Panel>
          ))}
        </Collapse>
      </Panel>
    </Collapse>
  );

  const renderForm = () => (
    <Collapse activeKey={['1']}>
      <Panel showArrow={false} header={translate(translationKeys.pages.support.contactForm.title)} key={'1'}>
        {translate(translationKeys.pages.support.contactForm.description)}
        <br />
        <br />
        <SupportContactForm />
      </Panel>
    </Collapse>
  );

  const layout = {
    md: 24,
    lg: 12,
  };

  return (
    <>
      <MasterPageHeader
        content={
          <PageDescription
            title={translate(translationKeys.pages.support.title)}
            content={
              <>
                <p>{parse(translate(translationKeys.pages.support.description))}</p>
                <p>{parse(translate(translationKeys.pages.support.videoLinkDescription))}</p>
              </>
            }
          />
        }
      />
      <MasterContent>
        <Row gutter={[16, 16]}>
          <Col {...layout}>
            <Row gutter={[16, 16]}>
              <Col>{renderSupportDocumentsSection()}</Col>
              <Col>{renderForm()}</Col>
              <Col>{renderFAQ()}</Col>
            </Row>
          </Col>
          <Col {...layout}>
            <Row gutter={[16, 16]}>
              <Col>
                <Installers
                  keyTitle="previousInstallers"
                  headerTitle={translate(translationKeys.pages.support.previousInstallers.title)}
                  subTitle={translate(translationKeys.pages.support.previousInstallers.subTitle)}
                  resourceConfigs={resourceConfigs}
                />
              </Col>
              <Col>{renderSupportVideosSection()}</Col>
            </Row>
          </Col>
        </Row>
      </MasterContent>
    </>
  );
};
