/* eslint-disable react/jsx-no-literals */

import React from 'react';
import { Alert, Collapse } from 'antd';
import { translate, translationKeys } from '../../../../../logic/translations/translations.service';
import { AlertProps } from 'antd/lib/alert';
import './import-users-result-panel.styles.scss';
import { ImportUsersResult } from '../../../../../logic/store/user/user.model';

const { Panel } = Collapse;

interface ImportUsersResultPanelProps {
  type: AlertProps['type'];
  show: boolean;
  title: string;
  results?: ImportUsersResult;
}

export const ImportUsersResultPanel: React.FC<ImportUsersResultPanelProps> = ({ show, type, title, results }) =>
  show ? (
    <div className={'import-result-container'}>
      <Alert
        type={type}
        message={
          <>
            {title}
            {results?.total ? (
              <Collapse>
                <Panel header={translate(translationKeys.pages.importUsers.seeDetails)} key="1">
                  {results?.rows?.map((row) => (
                    <div key={row.index}>
                      <div>
                        <strong>row index</strong>: {`${row.index}`}
                      </div>
                      <div>
                        <strong>errors</strong>: {`${JSON.stringify(row.error)}`}
                      </div>
                      <div className={'last-row-item'}>
                        <strong>row</strong>: {`${JSON.stringify(row.data)}`}
                      </div>
                    </div>
                  ))}
                  {(results.total || 0) > (results?.rows?.length || 0) && <>...</>}
                </Panel>
              </Collapse>
            ) : null}
          </>
        }
        showIcon
      ></Alert>
    </div>
  ) : null;
