import { EmergencyMode, Location } from '../../../api-models/api-models';
import { translate, translationKeys } from '../../../logic/translations/translations.service';
import { useDispatch, useSelector } from 'react-redux';

import { AUTHENTICATED_ROUTES } from '../../../logic/routes/routes.const';
import { Button } from 'antd';
import { ColumnProps } from 'antd/lib/table';
import { LocationFormFieldName } from '../../../common/location/location-form.field-name';
import { MasterContent } from '../../master-page/components/master-content/master-content';
import { MasterPageTableHeader } from '../../components/master-page-table-header/master-page-table-header';
import React from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Table } from '../../components/table/table';
import { TableHeaderButton } from '../../components/master-page-table-header/master-page-table-header.components';
import { TableName } from '../../../logic/store/tables/tables.action';
import { getAuthenticatedUserRole } from '../../../logic/store/authentication/authentication.selectors';
import { isEmpty } from 'lodash';
import { locationE911ActionCreators } from '../../../logic/store/location/location-e911/location-e911.action';
import { navigationActionCreators } from '../../../logic/store/navigation/navigation.actions';
import parse from 'html-react-parser';
import { parseId } from '../../../logic/utils/parse-id';
import queryString from 'query-string';
import { useConfirmActionModal } from '../../components/confirm-modal/confirm-modal';
import { useSearchInput } from '../../components/search-input/search-input';
import {
  getLocationColumn,
  shouldShowBackButton,
  shouldShowEditAction,
  shouldShowViewAction,
} from '../../../logic/location/location.helper';
import { PageDescription } from '../../components/page-description/page-description';
import FormValidationIcon from '../../components/form-validation-icon/form-validation-icon';

type LocationsE11PageProps = RouteComponentProps;

export const LocationsE911Page: React.FC<LocationsE11PageProps> = ({ location }) => {
  const searchInput = useSearchInput();
  const dispatch = useDispatch();
  const authenticatedUserRole = useSelector(getAuthenticatedUserRole);
  const { company_id } = queryString.parse(location.search);
  const company_id_parsed = parseId(company_id);

  const handleAddSingle = () =>
    dispatch(
      navigationActionCreators.navigateTo(
        AUTHENTICATED_ROUTES.EnhancedLocationsAdd,
        `?${queryString.stringify({ company_id: company_id_parsed })}`
      )
    );

  const handleEditSingle = (row: Location) => () =>
    dispatch(
      navigationActionCreators.navigateTo(
        AUTHENTICATED_ROUTES.EnhancedLocationsEdit,
        `?${queryString.stringify({ location_id: row.id })}`
      )
    );

  const handleViewSingle = (row: Location) => () =>
    dispatch(
      navigationActionCreators.navigateTo(
        AUTHENTICATED_ROUTES.EnhancedLocationsView,
        `?${queryString.stringify({ location_id: row.id })}`
      )
    );

  const handleDelete = (location: Location | undefined) => {
    if (location && location.id) {
      dispatch(locationE911ActionCreators.deleteSingle(location.id));
    }
  };

  const deleteModal = useConfirmActionModal(
    translate(translationKeys.modalDialog.deleteLocationE911),
    translate(translationKeys.modalDialog.areYouSureYouWantToRemoveThisLocationE911),
    handleDelete,
    locationE911ActionCreators.deleteSingle.name
  );

  const actionsColumn = (): ColumnProps<Location> => ({
    title: translate(translationKeys.forms.fields.actions),
    dataIndex: 'actions',
    key: 'actions',
    render: (value, row) => (
      <span>
        {shouldShowEditAction(authenticatedUserRole) && (
          <Button type={'link'} onClick={handleEditSingle(row)}>
            {translate(translationKeys.table.action.edit)}
          </Button>
        )}
        {shouldShowViewAction(authenticatedUserRole) && (
          <Button type={'link'} onClick={handleViewSingle(row)}>
            {translate(translationKeys.table.action.view)}
          </Button>
        )}
        <Button type={'link'} onClick={deleteModal.tryToPerformAction(row)}>
          {translate(translationKeys.table.action.delete)}
        </Button>
      </span>
    ),
  });

  const emergencyContactsColumn = (): ColumnProps<Location> => ({
    title: translate(translationKeys.pages.locationsE911.numberOfEmergencyContacts),
    dataIndex: 'numberOfEmergencyContacts',
    key: 'numberOfEmergencyContacts',
    render: (_, row) =>
      [row.first_emergency_contact_phone, row.second_emergency_contact_phone, row.third_emergency_contact_phone].filter(
        (phone) => !isEmpty(phone)
      ).length,
  });

  const getColumns = [
    actionsColumn,
    getLocationColumn(LocationFormFieldName.is_valid_in_bandwidth, false, (_, row) => {
      if (row.emergency_mode === EmergencyMode.E911) {
        return (
          <FormValidationIcon
            isValid={row.is_valid_in_bandwidth}
            tooltip={translate(translationKeys.forms.fields.location.isValidInBandwidthTooltip)}
          />
        );
      } else if (row.emergency_mode === EmergencyMode.Monitoring) {
        return (
          <FormValidationIcon
            isValid={!!row.monitoring_company_phone_number}
            tooltip={translate(translationKeys.forms.fields.location.isValidMonitoringTooltip)}
          />
        );
      }
    }),
    getLocationColumn(LocationFormFieldName.name),
    getLocationColumn(LocationFormFieldName.address),
    getLocationColumn(LocationFormFieldName.city),
    getLocationColumn(LocationFormFieldName.zip_code),
    getLocationColumn(LocationFormFieldName.state),
    getLocationColumn(LocationFormFieldName.country),
    emergencyContactsColumn,
  ];

  const renderAddSingleButton = () => (
    <TableHeaderButton type={'primary'} onClick={handleAddSingle}>
      {translate(translationKeys.buttons.addLocationE911)}
    </TableHeaderButton>
  );

  return (
    <>
      <MasterPageTableHeader
        searchInput={searchInput.render()}
        leftSection={renderAddSingleButton()}
        content={
          <PageDescription
            title={translate(translationKeys.pages.locationsE911.title)}
            content={parse(translate(translationKeys.pages.locationsE911.description))}
          />
        }
        showBack={shouldShowBackButton(authenticatedUserRole)}
      />
      <MasterContent>
        <Table
          tableName={TableName.locationE911}
          getColumns={getColumns}
          searchInput={searchInput}
          prefilters={{ ...(company_id_parsed ? { company_id: company_id_parsed } : {}), ...{ is_e911_enabled: true } }}
        />
      </MasterContent>
      {deleteModal.render()}
    </>
  );
};
