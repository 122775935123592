import { Collapse, Row, Col, Alert, Typography, Space, Modal } from 'antd';
import { translate, translationKeys } from '../../../logic/translations/translations.service';
import { useSelector } from 'react-redux';
import { getInProgress } from '../../../logic/store/process-tracker/process-tracker.selectors';
import { getAppVersions } from '../../../logic/store/user/user.selector';
import { userActionCreators } from '../../../logic/store/user/user.action';
import ECharts from '../e-charts';
import { useMediaQuery } from 'react-responsive';
import React, { useState } from 'react';
import { prepareAppVersion, prepareOutdatedAppVersion } from './app-versions-widget.logic';
import { WarningOutlined, DesktopOutlined, MobileOutlined } from '@ant-design/icons';
import OutdatedUsersList from './components/outdated-users-list/outdated-users-list';

const { Panel } = Collapse;

const SUPPORTED_DESKTOP_VERSION = '>=2.5.1';
const SUPPORTED_MOBILE_VERSION = '>=2.5.0';

interface AppVersionsWidgetProps {
  title: string;
  showTextMessage?: boolean;
  showOutdatedList?: boolean;
  timePeriod?: string;
}

const AppVersionsWidget: React.FC<AppVersionsWidgetProps> = ({
  title,
  showTextMessage,
  timePeriod,
  showOutdatedList,
}) => {
  const fetchAppVersionsAnalyticsInProgress = useSelector(
    getInProgress(userActionCreators.fetchAppVersionsAnalytics.name)
  );
  const appVersions = useSelector(getAppVersions);
  const isMobile = useMediaQuery({ maxWidth: 991 });
  const [isOutdatedListOpen, setIsOutdatedListOpen] = useState(false);

  const desktop = [...(appVersions?.WIN?.data || []), ...(appVersions?.MacOS?.data || [])];
  const mobile = [...(appVersions?.iOS?.data ?? []), ...(appVersions?.Android?.data ?? [])];

  const desktopData = prepareAppVersion(desktop);
  const mobileData = prepareAppVersion(mobile);

  const desktopOutdated = prepareOutdatedAppVersion(desktopData, SUPPORTED_DESKTOP_VERSION);
  const mobileOutdated = prepareOutdatedAppVersion(mobileData, SUPPORTED_MOBILE_VERSION);

  const dataAvailable = desktopData.length > 0 || mobileData.length > 0;
  const outdatedUsersNumber = desktopOutdated.value + mobileOutdated.value;
  const showSuccessMessage = dataAvailable && showTextMessage;
  const showOutdatedMessage = outdatedUsersNumber > 0;

  return (
    <>
      <Collapse defaultActiveKey={'appVersionWidget'}>
        <Panel showArrow={true} header={title} key="appVersionWidget">
          <Row style={{ flex: 1, justifyContent: 'center', alignItems: 'center' }}>
            <Col lg={{ span: 12 }}>
              <div style={{ height: isMobile ? 275 : 350 }}>
                <ECharts
                  title={translate(translationKeys.pages.dashboard.appVersions.desktopWidget)}
                  subtitle={translate(translationKeys.pages.dashboard.appVersions.desktopSupportedVersions)}
                  loading={fetchAppVersionsAnalyticsInProgress}
                  supportedVersion={SUPPORTED_DESKTOP_VERSION}
                  data={desktopData}
                />
              </div>
            </Col>
            <Col lg={{ span: 12 }}>
              <div style={{ height: isMobile ? 275 : 350 }}>
                <ECharts
                  title={translate(translationKeys.pages.dashboard.appVersions.mobileWidget)}
                  subtitle={translate(translationKeys.pages.dashboard.appVersions.mobileSupportedVersions)}
                  loading={fetchAppVersionsAnalyticsInProgress}
                  supportedVersion={SUPPORTED_MOBILE_VERSION}
                  data={mobileData}
                />
              </div>
            </Col>
            {showOutdatedMessage ? (
              <Space direction="vertical" align="center">
                <Alert
                  message={translate(translationKeys.pages.dashboard.appVersions.outdatedWarning, {
                    outdated: outdatedUsersNumber,
                  })}
                  type="error"
                  icon={<WarningOutlined />}
                  showIcon
                />
                {showOutdatedList && (
                  <Typography.Link onClick={() => setIsOutdatedListOpen(true)}>
                    {translate(translationKeys.pages.dashboard.appVersions.showOutdatedList)}
                  </Typography.Link>
                )}
              </Space>
            ) : (
              showSuccessMessage && (
                <Alert
                  message={translate(translationKeys.pages.dashboard.appVersions.thankYouMessage)}
                  type="success"
                  showIcon
                />
              )
            )}
          </Row>
        </Panel>
      </Collapse>
      {showOutdatedList && (
        <Modal
          bodyStyle={{ maxHeight: '65vh', overflowY: 'auto' }}
          title={translate(translationKeys.pages.dashboard.appVersions.outdatedListTitle, { timePeriod })}
          open={isOutdatedListOpen}
          footer={null}
          onCancel={() => setIsOutdatedListOpen(false)}
          closable
          centered
        >
          {desktopOutdated.users.length > 0 && (
            <OutdatedUsersList
              header={
                <>
                  <DesktopOutlined />
                  {`Desktop (${desktopOutdated.users.length})`}
                </>
              }
              list={desktopOutdated.users}
            />
          )}
          {mobileOutdated.users.length > 0 && (
            <OutdatedUsersList
              header={
                <>
                  <MobileOutlined />
                  {`Mobile (${mobileOutdated.users.length})`}
                </>
              }
              list={mobileOutdated.users}
            />
          )}
        </Modal>
      )}
    </>
  );
};

export default AppVersionsWidget;
