import React from 'react';
import { AUTHENTICATED_ROUTES, getMySettingsRoute } from '../../../../logic/routes/routes.const';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';
import { Role, User } from '../../../../api-models/api-models';
import {
  UserOutlined,
  ReadOutlined,
  AlertOutlined,
  QuestionCircleOutlined,
  BuildOutlined,
  BarChartOutlined,
  PhoneOutlined,
  CalendarOutlined,
  SettingOutlined,
  GlobalOutlined,
  LineChartOutlined,
} from '@ant-design/icons';
import { isRole } from '../../../authentication/models/role/role.utils';

export interface SideMenuItem {
  title?: string;
  key?: string;
  icon?: React.ReactElement;
  subItems?: SideMenuItem[];
}

type SideMenuItemsCreator = (authenticatedUser: User | undefined) => SideMenuItem[] | undefined;
type SideMenuCreator = (authenticatedUser: User | undefined) => ObjectByKey<SideMenuItem[]>;

const getMySettingsMenuItem: SideMenuItemsCreator = (authenticatedUser) => {
  const route = getMySettingsRoute(authenticatedUser);

  return !!route
    ? [
        {
          title: translate(translationKeys.sideMenu.mySettings),
          key: route,
          icon: <SettingOutlined />,
        },
      ]
    : undefined;
};

const dashboardMenuItem: SideMenuItem = {
  title: translate(translationKeys.sideMenu.dashboard),
  key: AUTHENTICATED_ROUTES.Dashboard,
  icon: <ReadOutlined />,
};

const getCompanyAdminOrGroupAdmin: SideMenuItemsCreator = (authenticatedUser) => [
  dashboardMenuItem,
  {
    title: translate(translationKeys.sideMenu.setup),
    key: AUTHENTICATED_ROUTES.Users,
    icon: <UserOutlined />,
    subItems: [
      {
        title: '1. ' + translate(translationKeys.sideMenu.groups),
        key: AUTHENTICATED_ROUTES.Groups,
      },
      {
        title: '2. ' + translate(translationKeys.sideMenu.alerts),
        key: AUTHENTICATED_ROUTES.Alerts,
      },
      {
        title: '3. ' + translate(translationKeys.sideMenu.users),
        key: AUTHENTICATED_ROUTES.Users,
      },
      {
        title: '4. ' + translate(translationKeys.sideMenu.channels),
        key: AUTHENTICATED_ROUTES.Channels,
      },
      {
        title: '5. ' + translate(translationKeys.sideMenu.locations),
        key: AUTHENTICATED_ROUTES.Locations,
      },
    ],
  },
  {
    title: translate(translationKeys.sideMenu.e911AddOn),
    key: AUTHENTICATED_ROUTES.EnhancedLocations,
    icon: <PhoneOutlined />,
  },
  {
    title: translate(translationKeys.sideMenu.ssoAddOn),
    key: AUTHENTICATED_ROUTES.SsoGroupMapping,
    icon: <GlobalOutlined />,
    subItems: [
      {
        title: translate(translationKeys.sideMenu.ssoGroupMappings),
        key: AUTHENTICATED_ROUTES.SsoGroupMapping,
      },
      {
        title: translate(translationKeys.sideMenu.ssoCompanyGroupMappings),
        key: AUTHENTICATED_ROUTES.SsoCompanyGroupMapping,
      },
    ],
  },
  isRole(Role.CompanyAdmin, authenticatedUser?.permission_level)
    ? {
        title: translate(translationKeys.sideMenu.reports),
        key: AUTHENTICATED_ROUTES.Reports,
        icon: <LineChartOutlined />,
      }
    : {},
  {
    title: translate(translationKeys.sideMenu.activeAlerts),
    key: AUTHENTICATED_ROUTES.ActiveAlerts,
    icon: <AlertOutlined />,
  },
  {
    title: translate(translationKeys.sideMenu.alertHistory),
    key: AUTHENTICATED_ROUTES.AlertHistory,
    icon: <AlertOutlined />,
  },
  ...(getMySettingsMenuItem(authenticatedUser) || []),
  {
    title: translate(translationKeys.sideMenu.support),
    key: AUTHENTICATED_ROUTES.Support,
    icon: <QuestionCircleOutlined />,
  },
];

const getRegularViewOnly: SideMenuItemsCreator = (authenticatedUser) => [
  {
    title: translate(translationKeys.sideMenu.dashboard),
    key: AUTHENTICATED_ROUTES.Dashboard,
    icon: <ReadOutlined />,
  },
  ...(getMySettingsMenuItem(authenticatedUser) || []),
];

export const getSideMenus: SideMenuCreator = (authenticatedUser) => ({
  [Role.SuperAdmin]: [
    {
      title: translate(translationKeys.sideMenu.dashboard),
      key: AUTHENTICATED_ROUTES.SuperAdminDashboard,
      icon: <ReadOutlined />,
    },
    {
      title: translate(translationKeys.sideMenu.setup),
      key: AUTHENTICATED_ROUTES.SuperAdminSuperAdminUsers,
      icon: <UserOutlined />,
      subItems: [
        {
          title: translate(translationKeys.sideMenu.superAdmins),
          key: AUTHENTICATED_ROUTES.SuperAdminSuperAdminUsers,
        },
        {
          title: translate(translationKeys.sideMenu.companyAdmins),
          key: AUTHENTICATED_ROUTES.SuperAdminCompanyAdminUsers,
        },
        {
          title: translate(translationKeys.sideMenu.ssoCompanyAdmins),
          key: AUTHENTICATED_ROUTES.SsoCompanyAdmins,
        },
      ],
    },
    {
      title: translate(translationKeys.sideMenu.companies),
      key: AUTHENTICATED_ROUTES.Companies,
      icon: <BuildOutlined />,
    } as SideMenuItem,
    {
      title: translate(translationKeys.sideMenu.trialAccounts),
      key: AUTHENTICATED_ROUTES.TrialCompanies,
      icon: <CalendarOutlined />,
    } as SideMenuItem,
    {
      title: translate(translationKeys.sideMenu.latestAlerts),
      key: AUTHENTICATED_ROUTES.LatestAlerts,
      icon: <AlertOutlined />,
    },
    {
      title: translate(translationKeys.sideMenu.analytics),
      key: AUTHENTICATED_ROUTES.Analytics,
      icon: <BarChartOutlined />,
    } as SideMenuItem,
  ] as SideMenuItem[],
  [Role.CompanyAdmin]: getCompanyAdminOrGroupAdmin(authenticatedUser),
  [Role.GroupAdmin]: getCompanyAdminOrGroupAdmin(authenticatedUser),
  [Role.Regular]: getRegularViewOnly(authenticatedUser),
  [Role.ViewOnly]: getRegularViewOnly(authenticatedUser),
});
