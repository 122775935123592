import { translationKeys } from '../../logic/translations/translations.service';
import { Resource, ResourceConfig } from '../components/resources/resource.const';

const BOLD_TEXT_CLASS = 'resource-link__text-bold';
const INSTALLER_REGULAR_USER_TOOLTIP = translationKeys.pages.dashboard.installers.regularUserTooltip;
const INSTALLER_ADMIN_USER_TOOLTIP = translationKeys.pages.dashboard.installers.adminUsersTooltip;
const INSTALLER_NOTE = translationKeys.pages.dashboard.installers.note;

export const getNewestResourceConfigs = (
  androidShow: (data?: any) => void,
  iOSShow: (data?: any) => void,
  appleWatchShow: (data?: any) => void
): ResourceConfig[] => [
  {
    resource: Resource.desktopAppInstallerWindowsRegularUser,
    textClassName: BOLD_TEXT_CLASS,
    tooltipMessage: INSTALLER_REGULAR_USER_TOOLTIP,
  },
  {
    resource: Resource.desktopAppInstallerMac,
    textClassName: BOLD_TEXT_CLASS,
  },
  {
    resource: Resource.androidApp,
    textClassName: BOLD_TEXT_CLASS,
    qrModalProps: [
      {
        resource: Resource.seeAndroidAppQrCode,
        action: androidShow,
        noPaddingLeft: true,
      },
    ],
  },
  {
    resource: Resource.iOSApp,
    textClassName: BOLD_TEXT_CLASS,
    qrModalProps: [
      {
        resource: Resource.seeiOSAppQrCode,
        action: iOSShow || (() => {}),
        noPaddingLeft: true,
      },
    ],
  },
  {
    resource: Resource.appleWatch,
    textClassName: BOLD_TEXT_CLASS,
    qrModalProps: [
      {
        resource: Resource.seeAppleWatchQrCode,
        action: appleWatchShow || (() => {}),
        noPaddingLeft: true,
      },
    ],
  },
  { resource: Resource.divider },
  {
    resource: Resource.desktopAppInstallerWindowsDomainAdminUser,
    textClassName: BOLD_TEXT_CLASS,
    tooltipMessage: INSTALLER_ADMIN_USER_TOOLTIP,
    notes: INSTALLER_NOTE,
  },
];
