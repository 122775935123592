import { Tooltip } from 'antd';
import React, { useMemo } from 'react';
import styled from 'styled-components';
import { User } from '../../../../api-models/api-models';
import { formatDateTimeForDisplay } from '../../../../logic/date-time/date-time.format';
import { now } from '../../../../logic/date-time/date-time.now';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';
import {
  getLastActive,
  getLastActiveBreak,
  getLastActiveDevice,
  getLastUsedAppVersionText,
  getLastUsedOsText,
  LastActiveTresholdDay,
} from './last-active.logic';
import { ActivityBadge, badgeColorByBreak } from './last-active.common';
import { LastActiveBreak } from './last-active-break.model';
import { DateTime } from 'luxon';

interface LastActiveColumnCellProps {
  user: User | undefined;
}

export const LastActiveColumnCell: React.FC<LastActiveColumnCellProps> = ({ user }) => {
  const lastActiveDevice = useMemo(() => getLastActiveDevice(user?.last_used_devices), [user]);
  const lastActive = getLastActive(lastActiveDevice);
  const lastActiveBreak = getLastActiveBreak(now())(LastActiveTresholdDay)(lastActive);
  const badgeColor = lastActiveBreak ? badgeColorByBreak[lastActiveBreak] : undefined;
  const osText = getLastUsedOsText(lastActiveDevice);
  const appVersion = getLastUsedAppVersionText(lastActiveDevice);
  const dateLabel = (lastActive && DateTime.fromISO(lastActive).toRelative()) || '';
  const dateLabelNoSufix =
    (lastActive && DateTime.now().diff(DateTime.fromISO(lastActive), ['days', 'hours']).toHuman()) || '';
  const lastActiveLabel =
    lastActiveBreak === LastActiveBreak.NoBreak
      ? translate(translationKeys.pages.users.lastActive.lastSeen, { dateLabel })
      : translate(translationKeys.pages.users.lastActive.hasntLoggedInFor, { dateLabel: dateLabelNoSufix });

  const tooltipContent = (
    <ToolTipContent>
      <div>{formatDateTimeForDisplay(lastActive)}</div>
      <div>{lastActiveLabel}</div>
      <div>{translate(translationKeys.pages.users.lastActive.lastKnownDevices)}</div>
      {user?.last_used_devices?.map((device) => (
        <div key={device.created_at}>{`${device.app_version} (${getLastUsedOsText(device)}): ${formatDateTimeForDisplay(
          device.modified_at
        )}`}</div>
      ))}
    </ToolTipContent>
  );

  return !!lastActive && badgeColor ? (
    <Container>
      <Tooltip placement="bottom" title={tooltipContent}>
        <ActivityBadge color={badgeColor} />
      </Tooltip>
      <DescriptionContainer>
        <Description>{osText}</Description>
        <Description>{appVersion}</Description>
      </DescriptionContainer>
    </Container>
  ) : null;
};

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

const DescriptionContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const Description = styled.div`
  white-space: nowrap;
`;

const ToolTipContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;
