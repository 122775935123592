import { CheckCircleTwoTone, WarningTwoTone } from '@ant-design/icons';
import { Tooltip } from 'antd';

interface FormValidationIconProps {
  isValid?: boolean;
  tooltip?: string;
}

const FormValidationIcon: React.FC<FormValidationIconProps> = ({ isValid, tooltip }) => {
  return (
    <Tooltip title={tooltip}>
      {isValid ? <CheckCircleTwoTone twoToneColor="#52c41a" /> : <WarningTwoTone twoToneColor="#eb2f96" />}
    </Tooltip>
  );
};

export default FormValidationIcon;
