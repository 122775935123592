import { ReactECharts } from '../react-echarts';
import semver from 'semver';
import React from 'react';
import { getIconByOsTypeRaw } from '../../../logic/utils/get-icon-by-ostype';

interface EChartsProps {
  title: string;
  data: Array<{ value: number; name: string }>;
  loading: boolean;
  supportedVersion: string;
  subtitle?: string;
}

const TOOLTIP_ITEM_LIMIT = 10;

const ECharts: React.FC<EChartsProps> = ({ title, data, loading, subtitle, supportedVersion }) => {
  const getCustomColors = (data: Array<{ value: number; name: string }>) => {
    const colors = data.map((item) => {
      return semver.satisfies(item.name, supportedVersion) ? '#52c41a' : '#ff4d4f';
    });
    return colors;
  };

  return (
    <ReactECharts
      loading={loading}
      option={{
        title: {
          text: title,
          subtext: subtitle,
          left: 'center',
        },
        tooltip: {
          trigger: 'item',
          confine: true,
          formatter: (params: any) => {
            return `
              <span style="width: 24px; height: 24px; color: ${params.color};">&#9679;</span>
              <b>${params.name}</b> (${params.value})<br/>
              <small>${params.data.users
                .slice(0, TOOLTIP_ITEM_LIMIT)
                .map((user: any) => `${getIconByOsTypeRaw(user.os_type)} ${user.first_name} ${user.last_name}`)
                .join('<br/>')}
              </small>
              ${params.data.users.length > TOOLTIP_ITEM_LIMIT ? '<br/> ...' : ''}
            `;
          },
        },
        series: [
          {
            type: 'pie',
            radius: '45%',
            data,
            label: {
              formatter: '{b}: {d}%',
            },
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)',
              },
            },
            color: getCustomColors(data),
          },
        ],
      }}
    />
  );
};

export default ECharts;
