import React from 'react';
import { MasterContent } from '../../../master-page/components/master-content/master-content';
import { Table, SortableFilterableColumn } from '../../../components/table/table';
import { AlertFieldName } from './alerts.field-name';
import { Alert } from '../../../../api-models/api-models';
import { TableName } from '../../../../logic/store/tables/tables.action';
import { useSearchInput } from '../../../components/search-input/search-input';
import { getAlertColumns } from './alerts.columns';
import { Descriptions, Empty, Button } from 'antd';
import { formatDateTimeForDisplay } from '../../../../logic/date-time/date-time.format';
import { stringifyFields } from '../../../../logic/utils/stringify-fields';
import { QueryStringPrefilters } from '../../../../logic/store/tables/tables.reducer';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';
import { useDispatch, useSelector } from 'react-redux';
import { getInProgress, getProgress } from '../../../../logic/store/process-tracker/process-tracker.selectors';
import { alertActionCreators } from '../../../../logic/store/alert/alert.action';
import { getProgressSpinner } from '../../../components/progress-spinner/progress-spinner';
import { MasterPageTableHeader } from '../../../components/master-page-table-header/master-page-table-header';

export interface AlertsTemplatePageProps {
  columnsFields: AlertFieldName[];
  prefilters: QueryStringPrefilters;
  tableName: TableName;
  showLowerAlertAction: boolean;
  showFetchPdfReportAction: boolean;
  headerTitle?: string;
  filteringInput?: React.ReactNode;
}

export const AlertsTemplatePage: React.FC<AlertsTemplatePageProps> = ({
  columnsFields,
  prefilters,
  tableName,
  showLowerAlertAction,
  showFetchPdfReportAction,
  headerTitle,
  filteringInput,
}) => {
  const searchInput = useSearchInput();
  const dispatch = useDispatch();
  const lowerInProgress = useSelector(getInProgress(alertActionCreators.lower.name));
  const fetchPdfReportInProgress = useSelector(getInProgress(alertActionCreators.fetchPdfReport.name));
  const fetchPdfReportProgress = useSelector(getProgress(alertActionCreators.fetchPdfReport.name));
  const inProgress = lowerInProgress || fetchPdfReportInProgress;
  const progressSpinner = getProgressSpinner(inProgress, fetchPdfReportProgress);

  const handleLowerAlert = (row: Alert) => () => row.id && dispatch(alertActionCreators.lower(row.id));
  const handleFetchPdfReport = (row: Alert) => () => row.id && dispatch(alertActionCreators.fetchPdfReport(row.id));

  const actionsColumn = (): SortableFilterableColumn<Alert> => () => ({
    title: translate(translationKeys.forms.fields.actions),
    dataIndex: 'actions',
    key: 'actions',
    render: (value, row) => (
      <span>
        {showLowerAlertAction && (
          <Button type={'link'} onClick={handleLowerAlert(row)}>
            {translate(translationKeys.table.action.lower)}
          </Button>
        )}
        {showFetchPdfReportAction && (
          <Button type={'link'} onClick={handleFetchPdfReport(row)}>
            {translate(translationKeys.table.action.getReport)}
          </Button>
        )}
      </span>
    ),
  });

  const renderChatMessages = (row: Alert) =>
    row.messages?.length ? (
      <div>
        <Descriptions column={{ md: 1, sm: 1 }}>
          {row.messages?.map((message) => (
            <Descriptions.Item
              label={stringifyFields(
                '',
                '(',
                formatDateTimeForDisplay(message.created_at),
                ') ',
                message.participant_full_name
              )}
            >
              {stringifyFields('', message.text)}
            </Descriptions.Item>
          ))}
        </Descriptions>
      </div>
    ) : (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        description={translate(translationKeys.forms.fields.alert.noChatMessages)}
      />
    );

  const showActions = showLowerAlertAction || showFetchPdfReportAction;
  const tableColumns = showActions
    ? [actionsColumn(), ...getAlertColumns(columnsFields)]
    : [...getAlertColumns(columnsFields)];

  return (
    <>
      <MasterPageTableHeader searchInput={searchInput.render()} filteringInput={filteringInput} content={headerTitle} />
      <MasterContent>
        <Table
          tableName={tableName}
          getColumns={tableColumns}
          prefilters={prefilters}
          initialSorter={{
            field: AlertFieldName.created_at,
            order: 'descend',
            column: {},
            columnKey: AlertFieldName.created_at,
          }}
          expandedRowRender={renderChatMessages}
          searchInput={searchInput}
          loading={progressSpinner}
        />
      </MasterContent>
    </>
  );
};
