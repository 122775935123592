export enum LocationFormFieldName {
  id = 'id',
  name = 'name',
  company_id = 'company_id',
  address = 'address',
  city = 'city',
  zip_code = 'zip_code',
  state = 'state',
  country = 'country',
  notes = 'notes',
  geolocation = 'geolocation',
  is_geolocation_enabled = 'is_geolocation_enabled',
  geolocation_id = 'geolocation_id',
  user_ids = 'user_ids',
  first_emergency_contact_name = 'first_emergency_contact_name',
  first_emergency_contact_phone = 'first_emergency_contact_phone',
  first_emergency_contact_id = 'first_emergency_contact_id',
  first_emergency_contact_phone_country_code = 'first_emergency_contact_phone_country_code',
  second_emergency_contact_name = 'second_emergency_contact_name',
  second_emergency_contact_phone = 'second_emergency_contact_phone',
  second_emergency_contact_id = 'second_emergency_contact_id',
  second_emergency_contact_phone_country_code = 'second_emergency_contact_phone_country_code',
  third_emergency_contact_name = 'third_emergency_contact_name',
  third_emergency_contact_phone = 'third_emergency_contact_phone',
  third_emergency_contact_id = 'third_emergency_contact_id',
  third_emergency_contact_phone_country_code = 'third_emergency_contact_phone_country_code',
  site = 'site',
  emergency_mode = 'emergency_mode',
  monitoring_company_phone_number = 'monitoring_company_phone_number',
  monitoring_company_phone_number_country_code = 'monitoring_company_phone_number_country_code',
  monitoring_company_voice_message = 'monitoring_company_voice_message',
  monitoring_company_notification_sms_number_country_code = 'monitoring_company_notification_sms_number_country_code',
  monitoring_company_notification_sms_number = 'monitoring_company_notification_sms_number',
  monitoring_company_notification_email = 'monitoring_company_notification_email',
  is_valid_in_bandwidth = 'is_valid_in_bandwidth',
}
