import { FieldTimeOutlined } from '@ant-design/icons';
import { Select, Space } from 'antd';
import { translate, translationKeys } from '../../../../../logic/translations/translations.service';
import styled from 'styled-components';

const TIME_PERIODS = [1, 7, 14, 30, 90, 180];

interface TimePeriodDropdownProps {
  onChange:
    | ((
        value: number,
        option:
          | {
              value: number;
              label: string;
            }
          | {
              value: number;
              label: string;
            }[]
      ) => void)
    | undefined;
}

export const IsLastDaySelected = (timePeriod: number) => timePeriod === 1;

const TimePeriodDropdown: React.FC<TimePeriodDropdownProps> = ({ onChange }) => {
  const timePeriodOptions = TIME_PERIODS.map((timePeriod) => ({
    value: timePeriod,
    label: IsLastDaySelected(timePeriod)
      ? translate(translationKeys.pages.dashboard.appVersions.timeLabelOne)
      : translate(translationKeys.pages.dashboard.appVersions.timeLabelMany, { timePeriod }),
  }));

  return (
    <StyledSpace>
      <FieldTimeOutlined style={{ fontSize: 24, marginTop: 4 }} />
      <Select style={{ minWidth: 110 }} defaultValue={7} options={timePeriodOptions} onChange={onChange} />
    </StyledSpace>
  );
};

const StyledSpace = styled(Space)`
  padding-top: 5px;
`;

StyledSpace.defaultProps = {
  size: [10, 5],
  wrap: true,
};

export default TimePeriodDropdown;
