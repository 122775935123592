import { Select } from 'antd';
import { useState } from 'react';
import styled from 'styled-components';
import { EmergencyMode } from '../../../../api-models/api-models';
import { getDefaultSelectOptions } from '../../form-inputs/select-option/select-option';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';

export const useAlertEmergencyModeFilter = () => {
  const [selectedFilter, setSelectedFilter] = useState<string[]>();

  const emergencyModes = [
    {
      id: EmergencyMode.E911,
      name: 'E911',
    },
    {
      id: EmergencyMode.Monitoring,
      name: 'Monitoring',
    },
  ];

  const emergencyModeOptions = getDefaultSelectOptions(emergencyModes);

  const handleOnChange = (value?: string[]) => {
    setSelectedFilter(value);
  };

  return {
    render: () => {
      return (
        <FilterInputStyled
          placeholder={translate(translationKeys.buttons.placeholders.alertEmergencyModeText)}
          options={emergencyModeOptions}
          filterOption={(input, option) => (option?.label.toLowerCase() ?? '').includes(input.toLowerCase())}
          onChange={(value) => handleOnChange(value as string[])}
          allowClear
        />
      );
    },
    selectedFilter,
  };
};

const FilterInputStyled = styled(Select)`
  margin-bottom: 8px;
  margin-left: 10px;
  min-width: 200px;
`;
