import {
  ContactInfoFieldName,
  LocationTagSection,
  UserFormFieldName,
} from '../user-form-template/user-form-field-name';
import { translate, translationKeys } from '../../../../logic/translations/translations.service';
import { getSortableFilterableColumnCreator, Render } from '../../../components/table/table.column';
import { User } from '../../../../api-models/api-models';
import { mapGroupsToNames } from '../../../../logic/store/group/group.logic';
import styled from 'styled-components';
import { LastActiveColumnCell } from '../../components/last-active/last-active';
import { SortableFilterableColumn } from '../../../components/table/table';
import { Tooltip } from 'antd';
import { QuestionCircleOutlined } from '@ant-design/icons';
import { colors } from '../../../../styles/colors';
import { LastActiveLegend } from '../../components/last-active/last-active-legend';

export type UserFormFieldNameColumnsType = Exclude<
  UserFormFieldName,
  'default_location_header' | 'default_location_label'
>;

const getUserColumn = getSortableFilterableColumnCreator<UserFormFieldName, User>(translationKeys.forms.fields.user);
const getContactInfoColumn = getSortableFilterableColumnCreator<ContactInfoFieldName, User>(
  translationKeys.forms.fields.contact_info
);

const getLocationColumn = getSortableFilterableColumnCreator<LocationTagSection, User>(
  translationKeys.forms.fields.user
);

const getColumnWithToolTip =
  (fieldName: UserFormFieldNameColumnsType, sort = true, render?: Render<User>): SortableFilterableColumn<User> =>
  (filter, sorter) => ({
    title: () => (
      <div>
        <div>
          {translate(translationKeys.forms.fields.user[fieldName])}
          &nbsp; &nbsp;
          <Tooltip color={colors.white} placement="bottom" title={<LastActiveLegend />}>
            <QuestionCircleOutlined />
          </Tooltip>
        </div>
      </div>
    ),
    dataIndex: fieldName,
    key: fieldName,
    sorter: sort,
    sortOrder: sorter?.field === fieldName ? sorter?.order : undefined,
    render,
  });

const userColumns = {
  [UserFormFieldName.id]: getUserColumn(UserFormFieldName.id),
  [UserFormFieldName.first_name]: getUserColumn(UserFormFieldName.first_name),
  [UserFormFieldName.last_name]: getUserColumn(UserFormFieldName.last_name),
  [UserFormFieldName.email]: getUserColumn(UserFormFieldName.email),
  [UserFormFieldName.is_email_verified]: getUserColumn(UserFormFieldName.is_email_verified),
  [UserFormFieldName.password]: getUserColumn(UserFormFieldName.password),
  [UserFormFieldName.setNewPasswordButton]: getUserColumn(UserFormFieldName.setNewPasswordButton),
  [UserFormFieldName.company_id]: getUserColumn(UserFormFieldName.company_id, false, (value, row) => row.company_name),
  [ContactInfoFieldName.address]: getContactInfoColumn(
    ContactInfoFieldName.address,
    false,
    (value, row) => row.contact_info?.address
  ),
  [ContactInfoFieldName.city]: getContactInfoColumn(
    ContactInfoFieldName.city,
    false,
    (value, row) => row.contact_info?.city
  ),
  [ContactInfoFieldName.state]: getContactInfoColumn(
    ContactInfoFieldName.state,
    false,
    (value, row) => row.contact_info?.state
  ),
  [ContactInfoFieldName.zip_code]: getContactInfoColumn(
    ContactInfoFieldName.zip_code,
    false,
    (value, row) => row.contact_info?.zip_code
  ),
  [ContactInfoFieldName.voice_call_phone_country_code]: getContactInfoColumn(
    ContactInfoFieldName.voice_call_phone_country_code
  ),
  [ContactInfoFieldName.voice_call_phone]: getContactInfoColumn(
    ContactInfoFieldName.voice_call_phone,
    false,
    (value, row) => row.contact_info?.voice_call_phone
  ),
  [UserFormFieldName.status]: getUserColumn(
    UserFormFieldName.status,
    true,
    (value, row) => row.status && translate(translationKeys.accountStatus[row.status])
  ),
  [UserFormFieldName.permission_level]: getUserColumn(
    UserFormFieldName.permission_level,
    true,
    (value, row) => `${!!row.permission_level && translate(translationKeys.role[row.permission_level])}`
  ),
  [ContactInfoFieldName.department]: getContactInfoColumn(
    ContactInfoFieldName.department,
    false,
    (value, row) => row.contact_info?.department
  ),
  [ContactInfoFieldName.sms_phone_country_code]: getContactInfoColumn(ContactInfoFieldName.sms_phone_country_code),
  [ContactInfoFieldName.sms_phone]: getContactInfoColumn(
    ContactInfoFieldName.sms_phone,
    false,
    (value, row) => row.contact_info?.sms_phone
  ),
  [UserFormFieldName.groups_ids]: getUserColumn(UserFormFieldName.groups_ids),
  [UserFormFieldName.groups]: getUserColumn(UserFormFieldName.groups, false, (value, row) =>
    mapGroupsToNames(row.groups_names)
  ),
  [LocationTagSection.section]: getLocationColumn(LocationTagSection.section),
  [UserFormFieldName.contact_info]: getUserColumn(UserFormFieldName.contact_info),
  [UserFormFieldName.hb1_alert_type_id]: getUserColumn(UserFormFieldName.hb1_alert_type_id),
  [UserFormFieldName.hb2_alert_type_id]: getUserColumn(UserFormFieldName.hb2_alert_type_id),
  [UserFormFieldName.main1_alert_type_id]: getUserColumn(UserFormFieldName.main1_alert_type_id),
  [UserFormFieldName.main2_alert_type_id]: getUserColumn(UserFormFieldName.main2_alert_type_id),
  [UserFormFieldName.hb1_message]: getUserColumn(UserFormFieldName.hb1_message),
  [UserFormFieldName.hb2_message]: getUserColumn(UserFormFieldName.hb2_message),
  [UserFormFieldName.main_alerts_block_Header]: getUserColumn(UserFormFieldName.main_alerts_block_Header),
  [UserFormFieldName.hardware_devices_block_Header]: getUserColumn(UserFormFieldName.hardware_devices_block_Header),
  [UserFormFieldName.if_button_1_pressed_header]: getUserColumn(UserFormFieldName.if_button_1_pressed_header),
  [UserFormFieldName.if_button_2_pressed_header]: getUserColumn(UserFormFieldName.if_button_2_pressed_header),
  [UserFormFieldName.last_active]: getColumnWithToolTip(UserFormFieldName.last_active, false, (_, row) => (
    <LastActiveColumnCell user={row} />
  )),
};

export const getUserColumns = (
  fieldNames: Array<UserFormFieldNameColumnsType | ContactInfoFieldName>,
  skipFieldNames?: Array<UserFormFieldNameColumnsType | ContactInfoFieldName>
) => fieldNames.flatMap((x) => (skipFieldNames?.includes(x) ? [] : userColumns[x]));

export const LastActiveBadge = styled.div``;
