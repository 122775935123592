import { map } from 'lodash';
import { Role, User } from '../../api-models/api-models';

export enum LANDING_ROUTES {
  Landing = '/',
}

export enum AUTHENTICATION_ROUTES {
  Login = '/login',
  CompleteNewPassword = '/complete-new-password',
  ForgotPassword = '/forgot-password',
  ResetPassword = '/reset-password',
  ChangePasswordSuccess = '/change-password-success',
  SubmitCompany = '/company-submit',
  Logout = '/logout',
  NoAuthentication = '/no-authentication',
  NoAuthorization = '/no-authorization',
  SessionExpired = '/session-expired',
  Signup = '/signup',
  SignupSuccess = '/signup-success',
}

export enum AUTHENTICATED_ROUTES {
  SuperAdminDashboard = '/super-admin/dashboard',
  SuperAdminSuperAdminUsers = '/super-admin/users/super-admins',
  SuperAdminAddSuperAdmin = '/super-admin/users/super-admins/add',
  SuperAdminEditSuperAdmin = '/super-admin/users/super-admins/edit',

  SuperAdminCompanyAdminUsers = '/super-admin/users/company-admins',
  SuperAdminAddCompanyAdmin = '/super-admin/users/company-admins/add',
  SuperAdminEditCompanyAdmin = '/super-admin/users/company-admins/edit',

  SsoCompanyAdmins = '/sso-company-admins',
  SsoCompanyAdminsAdd = '/sso-company-admins/add',

  SsoGroupMapping = '/sso-group-mapping',
  SsoGroupMappingAdd = '/sso-group-mapping/add',
  SsoGroupMappingEdit = '/sso-group-mapping/edit',

  SsoCompanyGroupMapping = '/sso-company-group-mapping',
  SsoCompanyGroupMappingAdd = '/sso-company-group-mapping/add',
  SsoCompanyGroupMappingEdit = '/sso-company-group-mapping/edit',

  TrialCompanies = '/trial-companies',
  TrialCompaniesAdd = '/trial-companies/add',
  TrialCompaniesEdit = '/trial-companies/edit',

  Companies = '/companies',
  CompaniesAdd = '/companies/add',
  CompaniesEdit = '/companies/edit',

  Analytics = '/analytics',

  LatestAlerts = '/latest-alerts',

  EnhancedLocations = '/locations-enhanced',
  EnhancedLocationsAdd = '/locations-enhanced/add',
  EnhancedLocationsEdit = '/locations-enhanced/edit',
  EnhancedLocationsView = '/locations-enhanced/view',

  CompanyDomains = '/company-sso-domains',
  CompanyDomainsAdd = '/company-sso-domains/add',
  CompanyDomainsEdit = '/company-sso-domains/edit',

  Users = '/users',
  AddUser = '/users/add',
  EditUser = '/users/edit',

  ImportUsers = '/users/import',

  Groups = '/groups',
  GroupsAdd = '/groups/add',
  GroupsEdit = '/groups/edit',

  Dashboard = '/dashboard',
  RegularUserEditMe = '/editme',

  Alerts = '/alerts',
  AlertAdd = '/alerts/add',
  AlertEdit = '/alerts/edit',
  AlertManageChannels = '/alerts/manage-channels',
  AlertManageDocuments = '/alerts/manage-documents',
  Channels = '/channels',
  ChannelsManage = '/channels/manage',
  AlertQuickResponses = '/alerts/quick-responses',

  Locations = '/locations',
  LocationsAdd = '/locations/add',
  LocationsEdit = '/locations/edit',
  LocationsView = '/locations/view',

  Reports = '/reports',

  ActiveAlerts = '/active-alerts',
  AlertHistory = '/alert-history',

  Support = '/support',

  NotFound = '/not-found',
}

export const getMasterPageRoutes = (): string[] => map(AUTHENTICATED_ROUTES, (route) => route);

export const defaultRoute = {
  [Role.SuperAdmin]: AUTHENTICATED_ROUTES.SuperAdminDashboard,
  [Role.CompanyAdmin]: AUTHENTICATED_ROUTES.Dashboard,
  [Role.GroupAdmin]: AUTHENTICATED_ROUTES.Dashboard,
  [Role.Regular]: AUTHENTICATED_ROUTES.Dashboard,
  [Role.ViewOnly]: AUTHENTICATED_ROUTES.Dashboard,
};

export const mySettingsRoute: ObjectByKey<
  AUTHENTICATED_ROUTES.SuperAdminEditSuperAdmin | AUTHENTICATED_ROUTES.EditUser | AUTHENTICATED_ROUTES.RegularUserEditMe
> = {
  [Role.SuperAdmin]: AUTHENTICATED_ROUTES.SuperAdminEditSuperAdmin,
  [Role.CompanyAdmin]: AUTHENTICATED_ROUTES.EditUser,
  [Role.GroupAdmin]: AUTHENTICATED_ROUTES.EditUser,
  [Role.Regular]: AUTHENTICATED_ROUTES.RegularUserEditMe,
  [Role.ViewOnly]: AUTHENTICATED_ROUTES.RegularUserEditMe,
};

export const getMySettingsRoute = (authenticatedUser: User | undefined) => {
  const route = !!authenticatedUser?.permission_level
    ? mySettingsRoute[authenticatedUser?.permission_level]
    : undefined;

  return authenticatedUser?.permission_level && authenticatedUser.id && route
    ? `${route}?id=${authenticatedUser.id}`
    : undefined;
};
