import { AppVersionData } from '../../../api-models/api-models';
import semver from 'semver';

export const prepareAppVersion = (appVersion: Array<AppVersionData>) => {
  let groupedData = appVersion.reduce<{ [id: string]: AppVersionData }>((acc, curr) => {
    if (!acc[curr.name]) {
      acc[curr.name] = { value: 0, name: curr.name, users: [] };
    }
    acc[curr.name] = {
      value: acc[curr.name].value + curr.value,
      name: curr.name,
      users: [
        ...acc[curr.name].users,
        ...curr.users.map((user) => ({
          ...user,
          version: curr.name,
          os_type: user.os_type,
        })),
      ],
    };
    return acc;
  }, {});
  return Object.keys(groupedData)
    .map((key) => groupedData[key])
    .sort((a, b) => semver.rcompare(a.name, b.name));
};

export const prepareOutdatedAppVersion = (appVersionData: AppVersionData[], supportedVersion: string) => {
  return appVersionData.reduce<{ value: number; users: any[] }>(
    (acc, version) =>
      semver.satisfies(version.name, supportedVersion)
        ? acc
        : {
            value: acc.value + version.value,
            users: [...acc.users, ...version.users],
          },
    { value: 0, users: [] }
  );
};
