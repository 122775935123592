import { compose } from 'redux';
import { wrapComponentsWithProps } from '../../../logic/hoc/wrap-components-with-props.hoc';
import { TableName } from '../../../logic/store/tables/tables.action';
import { AlertsTemplatePage, AlertsTemplatePageProps } from '../../alerts/pages/alert-template/alerts-template.page';
import { AlertFieldName } from '../../alerts/pages/alert-template/alerts.field-name';
import AlertEmergencyModeFilter from '../../components/alert-emergency-mode-filter/alert-emergency-mode-filter';

const LatestAlertsPageCreator = () => {
  return compose(
    wrapComponentsWithProps<AlertsTemplatePageProps>({
      columnsFields: [
        AlertFieldName.emergency_mode,
        AlertFieldName.company_name,
        AlertFieldName.name,
        AlertFieldName.created_at,
        AlertFieldName.messages,
      ],
      prefilters: {},
      tableName: TableName.alertAnalytics,
      showLowerAlertAction: false,
      showFetchPdfReportAction: false,
      filteringInput: <AlertEmergencyModeFilter tableName={TableName.alertAnalytics} />,
    })
  )(AlertsTemplatePage);
};

export default LatestAlertsPageCreator;
