import { Route, Switch } from 'react-router-dom';
import { AUTHENTICATED_ROUTES, AUTHENTICATION_ROUTES, getMasterPageRoutes, LANDING_ROUTES } from './routes.const';
import { NoAuthenticationPage } from '../../pages/authentication/pages/no-authentication/no-authentication.page';
import { NotFoundPage } from '../../pages/authentication/pages/not-found/not-found.page';
import { MasterPage } from '../../pages/master-page/master.page';
import { AuthorizedRoute } from './authorized-route/authorized-route.component';
import { LoginPage } from '../../pages/authentication/pages/login/login.page';
import { LogoutPage } from '../../pages/authentication/pages/logout/logout.page';
import { CompleteNewPasswordPage } from '../../pages/authentication/pages/complete-new-password/complete-new-password.page';
import { SessionExpiredPage } from '../../pages/authentication/pages/session-expired/session-expired.page';
import { ForgotPasswordPage } from '../../pages/authentication/pages/forgot-password/forgot-password.page';
import { LoginDiscoveryPage } from '../../pages/authentication/pages/login-discovery/login-discovery';
import { ChangePasswordSuccessPage } from '../../pages/authentication/pages/change-password-success/change-password-success.page';
import { ResetPasswordPage } from '../../pages/authentication/pages/reset-password/reset-password.page';
import { NoAuthorizationPage } from '../../pages/authentication/pages/no-authorization/no-authorization.page';
import { SuperAdminAddSuperAdminPage } from '../../pages/users/pages/super-admin-add-super-admin.page';
import { SuperAdminAddCompanyAdminPage } from '../../pages/users/pages/super-admin-add-company-admin.page';
import { SuperAdminSuperAdminUsersPage } from '../../pages/users/pages/super-admin-super-admin-users.page';
import { SuperAdminEditCompanyAdminPage } from '../../pages/users/pages/super-admin-edit-company-admin.page';
import { SuperAdminEditSuperAdminPage } from '../../pages/users/pages/super-admin-edit-super-admin.page';
import { UsersPage } from '../../pages/users/pages/users';
import { EditUserPageCreator } from '../../pages/users/pages/edit-user.page';
import { CompanyAddPageCreator } from '../../pages/company/pages/company-add.page';
import { CompanyEditPageCreator } from '../../pages/company/pages/company-edit.page';
import { AddUserPageCreator } from '../../pages/users/pages/add-user.page';
import { GroupsPage } from '../../pages/group/pages/groups.page';
import { GroupAddPageCreator } from '../../pages/group/pages/group-add.page';
import { GroupEditPage } from '../../pages/group/pages/group-edit.page';
import { getEqualOrHigherRoles } from '../../pages/authentication/models/role/role.utils';
import { RegularUserEditPageCreator } from '../../pages/users/pages/regular-user-edit.page';
import { AlertTypesPage } from '../../pages/alerts/pages/alert-types.page';
import { alertTypeAddPageCreator } from '../../pages/alerts/pages/alert-type-add.page';
import { AlertTypeEditPage } from '../../pages/alerts/pages/alert-type-edit.page';
import { ChannelsPage } from '../../pages/channels/pages/channels/channels.page';
import { SuperAdminCompanyAdminUsersPage } from '../../pages/users/pages/super-admin-company-admin-users.page';
import { Role } from '../../api-models/api-models';
import { DashboardPage } from '../../pages/dashboard/dashboard.page';
import { SupportPage } from '../../pages/support/support.page';
import { ActiveAlertPageCreator } from '../../pages/alerts/pages/active-alerts.page';
import { AlertHistoryPageCreator } from '../../pages/alerts/pages/alert-history.page';
import { AlertTypesChannelsPage } from '../../pages/alerts/pages/alert-types-channels.page';
import { AuthenticationGate } from '../../components/authentication-gate/authentication-gate';
import { AlertDocumentsPage } from '../../pages/alerts/pages/alert-documents/alert-documents.page';
import { importUsersPageCreator } from '../../pages/users/pages/import-users/import-users.page';
import { LocationAddPageCreator } from '../../pages/location-e911/pages/location-e911-add.page';
import { AnalyticsPage } from '../../pages/analytics/analytics.page';
import { AlertQuickResponsesPage } from '../../pages/alerts/pages/alert-custom-responses/alert-custom-responses.page';
import { SignupPage } from '../../pages/authentication/pages/signup/signup.page';
import { SignupSuccessPage } from '../../pages/authentication/pages/signup/signup-success.page';
import { TrialCompaniesPage } from '../../pages/company/pages/companies/trial-companies.page';
import { TrialCompanyAddPageCreator } from '../../pages/company/pages/trial-company-add.page';
import { LocationsE911Page } from '../../pages/location-e911/pages/locations-e911.page';
import { LocationEditPageCreator } from '../../pages/location-e911/pages/location-e911-edit.page';
import { LocationE911ViewPage } from '../../pages/location-e911/pages/location-e911-view.page';
import { LocationsPage } from '../../pages/location/pages/locations.page';
import { LocationAddPage } from '../../pages/location/pages/location-add.page';
import { LocationEditPage } from '../../pages/location/pages/location-edit.page';
import { LocationViewPage } from '../../pages/location/pages/location-view.page';
import { CompanyDomainsPage } from '../../pages/company-domains/pages/company-domains.page';
import { CompanyDomainsPageCreator } from '../../pages/company-domains/pages/company-domains-add.page';
import { CompanyDomainsEditPageCreator } from '../../pages/company-domains/pages/company-domains-edit.page';
import { SsoCompanyAdmins } from '../../pages/sso-company-admins/pages/sso-company-admins/sso-company-admins.page';
import { SsoCompanyAdminsAddPageCreator } from '../../pages/sso-company-admins/pages/sso-company-admins-add.page';
import { CompaniesPage } from '../../pages/company/pages/companies/companies.page';
import { SsoGroupMapping } from '../../pages/sso-group-mapping/pages/sso-group-mapping/sso-group-mapping.page';
import { SsoGroupMappingAddPageCreator } from '../../pages/sso-group-mapping/pages/sso-group-mapping-add.page';
import { SsoCompanyGroupMapping } from '../../pages/sso-company-group-mapping/pages/sso-company-group-mapping/sso-company-group-mapping.page';
import { SsoCompanyGroupMappingAddPageCreator } from '../../pages/sso-company-group-mapping/pages/sso-company-group-mapping-add.page';
import { SsoGroupMappingEditPageCreator } from '../../pages/sso-group-mapping/pages/sso-group-mapping-edit.page';
import { SsoCompanyGroupMappingEditPageCreator } from '../../pages/sso-company-group-mapping/pages/sso-company-group-mapping-edit.page';
import LatestAlertsPageCreator from '../../pages/alerts/pages/latest-alerts';
import SuperAdminDashboard from '../../pages/sa-dashboard/sa-dashboard';
import ReportsPage from '../../pages/reports/reports';

export const Routes = () => (
  <Switch>
    <Route exact path={AUTHENTICATION_ROUTES.Signup} component={SignupPage} />
    <Route exact path={AUTHENTICATION_ROUTES.SignupSuccess} component={SignupSuccessPage} />
    <Route exact path={LANDING_ROUTES.Landing} component={LoginDiscoveryPage} />
    <Route exact path={AUTHENTICATION_ROUTES.NoAuthentication} component={NoAuthenticationPage} />
    <Route exact path={AUTHENTICATION_ROUTES.NoAuthorization} component={NoAuthorizationPage} />
    <Route exact path={AUTHENTICATION_ROUTES.Login} component={LoginPage} />
    <Route exact path={AUTHENTICATION_ROUTES.CompleteNewPassword} component={CompleteNewPasswordPage} />
    <Route exact path={AUTHENTICATION_ROUTES.ForgotPassword} component={ForgotPasswordPage} />
    <Route exact path={AUTHENTICATION_ROUTES.ResetPassword} component={ResetPasswordPage} />
    <Route exact path={AUTHENTICATION_ROUTES.ChangePasswordSuccess} component={ChangePasswordSuccessPage} />
    <Route exact path={AUTHENTICATION_ROUTES.Logout} component={LogoutPage} />
    <Route exact path={AUTHENTICATION_ROUTES.SessionExpired} component={SessionExpiredPage} />
    <Route
      exact
      path={getMasterPageRoutes()}
      component={() => (
        <AuthenticationGate>
          <MasterPage>
            <Switch>
              {/* Super Admins */}
              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.SuperAdminDashboard}
                component={SuperAdminDashboard}
                roles={getEqualOrHigherRoles(Role.SuperAdmin)}
              />
              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.SuperAdminSuperAdminUsers}
                component={SuperAdminSuperAdminUsersPage}
                roles={getEqualOrHigherRoles(Role.SuperAdmin)}
              />
              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.SuperAdminCompanyAdminUsers}
                component={SuperAdminCompanyAdminUsersPage}
                roles={getEqualOrHigherRoles(Role.SuperAdmin)}
              />
              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.SuperAdminAddSuperAdmin}
                component={SuperAdminAddSuperAdminPage}
                roles={getEqualOrHigherRoles(Role.SuperAdmin)}
              />
              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.SuperAdminEditSuperAdmin}
                component={SuperAdminEditSuperAdminPage}
                roles={getEqualOrHigherRoles(Role.SuperAdmin)}
              />
              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.SuperAdminAddCompanyAdmin}
                component={SuperAdminAddCompanyAdminPage}
                roles={getEqualOrHigherRoles(Role.SuperAdmin)}
              />
              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.SuperAdminEditCompanyAdmin}
                component={SuperAdminEditCompanyAdminPage}
                roles={getEqualOrHigherRoles(Role.SuperAdmin)}
              />

              {/* SSO Company Admins */}
              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.SsoCompanyAdmins}
                component={SsoCompanyAdmins}
                roles={getEqualOrHigherRoles(Role.SuperAdmin)}
              />
              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.SsoCompanyAdminsAdd}
                componentCreator={SsoCompanyAdminsAddPageCreator}
                roles={getEqualOrHigherRoles(Role.SuperAdmin)}
              />

              {/* Companies */}
              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.Companies}
                component={CompaniesPage}
                roles={getEqualOrHigherRoles(Role.SuperAdmin)}
              />
              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.CompaniesAdd}
                componentCreator={CompanyAddPageCreator}
                roles={getEqualOrHigherRoles(Role.SuperAdmin)}
              />
              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.CompaniesEdit}
                componentCreator={CompanyEditPageCreator}
                roles={getEqualOrHigherRoles(Role.CompanyAdmin)}
              />
              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.TrialCompanies}
                component={TrialCompaniesPage}
                roles={getEqualOrHigherRoles(Role.SuperAdmin)}
              />
              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.TrialCompaniesAdd}
                componentCreator={TrialCompanyAddPageCreator}
                roles={getEqualOrHigherRoles(Role.SuperAdmin)}
              />
              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.TrialCompaniesEdit}
                componentCreator={CompanyEditPageCreator}
                roles={getEqualOrHigherRoles(Role.CompanyAdmin)}
              />
              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.LatestAlerts}
                componentCreator={LatestAlertsPageCreator}
                roles={getEqualOrHigherRoles(Role.SuperAdmin)}
              />
              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.Analytics}
                component={AnalyticsPage}
                roles={getEqualOrHigherRoles(Role.SuperAdmin)}
              />
              {/* Company Admins */}
              {/* Group Admins */}
              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.Dashboard}
                component={DashboardPage}
                roles={getEqualOrHigherRoles(Role.ViewOnly)}
              />

              {/** SSO Group Mappings */}

              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.SsoGroupMapping}
                component={SsoGroupMapping}
                roles={getEqualOrHigherRoles(Role.CompanyAdmin)}
              />
              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.SsoGroupMappingAdd}
                componentCreator={SsoGroupMappingAddPageCreator}
                roles={getEqualOrHigherRoles(Role.CompanyAdmin)}
              />
              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.SsoGroupMappingEdit}
                componentCreator={SsoGroupMappingEditPageCreator}
                roles={getEqualOrHigherRoles(Role.CompanyAdmin)}
              />

              {/** SSO Alert Group Mappings */}
              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.SsoCompanyGroupMapping}
                component={SsoCompanyGroupMapping}
                roles={getEqualOrHigherRoles(Role.CompanyAdmin)}
              />
              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.SsoCompanyGroupMappingAdd}
                componentCreator={SsoCompanyGroupMappingAddPageCreator}
                roles={getEqualOrHigherRoles(Role.CompanyAdmin)}
              />
              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.SsoCompanyGroupMappingEdit}
                componentCreator={SsoCompanyGroupMappingEditPageCreator}
                roles={getEqualOrHigherRoles(Role.CompanyAdmin)}
              />

              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.Support}
                component={SupportPage}
                roles={getEqualOrHigherRoles(Role.GroupAdmin)}
              />
              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.Users}
                component={UsersPage}
                roles={getEqualOrHigherRoles(Role.GroupAdmin)}
              />
              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.AddUser}
                componentCreator={AddUserPageCreator}
                roles={[Role.CompanyAdmin, Role.GroupAdmin]}
              />
              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.EditUser}
                componentCreator={EditUserPageCreator}
                roles={getEqualOrHigherRoles(Role.ViewOnly)}
              />
              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.ImportUsers}
                componentCreator={importUsersPageCreator}
                roles={[Role.CompanyAdmin, Role.GroupAdmin]}
              />

              {/** Reports */}
              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.Reports}
                component={ReportsPage}
                roles={getEqualOrHigherRoles(Role.CompanyAdmin)}
              />

              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.Alerts}
                component={AlertTypesPage}
                roles={getEqualOrHigherRoles(Role.GroupAdmin)}
              />
              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.AlertAdd}
                componentCreator={alertTypeAddPageCreator}
                roles={getEqualOrHigherRoles(Role.GroupAdmin)}
              />
              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.AlertEdit}
                component={AlertTypeEditPage}
                roles={getEqualOrHigherRoles(Role.GroupAdmin)}
              />
              <AuthorizedRoute
                exact
                path={[AUTHENTICATED_ROUTES.AlertManageChannels, AUTHENTICATED_ROUTES.ChannelsManage]}
                component={ChannelsPage}
                roles={getEqualOrHigherRoles(Role.GroupAdmin)}
              />
              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.Channels}
                component={AlertTypesChannelsPage}
                roles={getEqualOrHigherRoles(Role.GroupAdmin)}
              />
              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.AlertManageDocuments}
                component={AlertDocumentsPage}
                roles={getEqualOrHigherRoles(Role.GroupAdmin)}
              />
              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.ActiveAlerts}
                componentCreator={ActiveAlertPageCreator}
                roles={getEqualOrHigherRoles(Role.GroupAdmin)}
              />
              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.AlertHistory}
                componentCreator={AlertHistoryPageCreator}
                roles={getEqualOrHigherRoles(Role.GroupAdmin)}
              />
              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.Groups}
                component={GroupsPage}
                roles={getEqualOrHigherRoles(Role.GroupAdmin)}
              />
              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.GroupsAdd}
                componentCreator={GroupAddPageCreator}
                roles={getEqualOrHigherRoles(Role.GroupAdmin)}
              />
              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.GroupsEdit}
                component={GroupEditPage}
                roles={getEqualOrHigherRoles(Role.GroupAdmin)}
              />
              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.EnhancedLocations}
                component={LocationsE911Page}
                roles={getEqualOrHigherRoles(Role.GroupAdmin)}
              />
              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.EnhancedLocationsAdd}
                componentCreator={LocationAddPageCreator}
                roles={getEqualOrHigherRoles(Role.GroupAdmin)}
              />
              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.EnhancedLocationsEdit}
                componentCreator={LocationEditPageCreator}
                roles={getEqualOrHigherRoles(Role.GroupAdmin)}
              />
              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.EnhancedLocationsView}
                component={LocationE911ViewPage}
                roles={getEqualOrHigherRoles(Role.GroupAdmin)}
              />
              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.CompanyDomains}
                component={CompanyDomainsPage}
                roles={getEqualOrHigherRoles(Role.SuperAdmin)}
              />
              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.CompanyDomainsAdd}
                componentCreator={CompanyDomainsPageCreator}
                roles={getEqualOrHigherRoles(Role.SuperAdmin)}
              />
              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.CompanyDomainsEdit}
                componentCreator={CompanyDomainsEditPageCreator}
                roles={getEqualOrHigherRoles(Role.SuperAdmin)}
              />
              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.AlertQuickResponses}
                component={AlertQuickResponsesPage}
                roles={getEqualOrHigherRoles(Role.GroupAdmin)}
              />
              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.Locations}
                component={LocationsPage}
                roles={getEqualOrHigherRoles(Role.GroupAdmin)}
              />

              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.LocationsAdd}
                component={LocationAddPage}
                roles={getEqualOrHigherRoles(Role.GroupAdmin)}
              />

              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.LocationsEdit}
                component={LocationEditPage}
                roles={getEqualOrHigherRoles(Role.GroupAdmin)}
              />

              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.LocationsView}
                component={LocationViewPage}
                roles={getEqualOrHigherRoles(Role.GroupAdmin)}
              />

              {/* Regular User */}
              {/* View Only User */}
              <AuthorizedRoute
                exact
                path={AUTHENTICATED_ROUTES.RegularUserEditMe}
                componentCreator={RegularUserEditPageCreator}
                roles={[Role.Regular, Role.ViewOnly]}
              />
              <Route exact path={AUTHENTICATED_ROUTES.NotFound} component={NotFoundPage} />
            </Switch>
          </MasterPage>
        </AuthenticationGate>
      )}
    />
    <Route component={NotFoundPage} />
  </Switch>
);
