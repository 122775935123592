import { useEffect } from 'react';
import { useAlertEmergencyModeFilter } from './hooks/useAlertEmergencyModeFilter';
import { useDispatch } from 'react-redux';
import { tableActionCreators, TableName } from '../../../logic/store/tables/tables.action';

interface AlertEmergencyModeFilterProps {
  tableName?: TableName;
}

const AlertEmergencyModeFilter: React.FC<AlertEmergencyModeFilterProps> = ({ tableName }) => {
  const dispatch = useDispatch();
  const alertEmergencyModeFilter = useAlertEmergencyModeFilter();

  useEffect(() => {
    if (tableName) {
      dispatch(
        tableActionCreators.updateQuery(tableName, {
          filters: { emergency_mode: alertEmergencyModeFilter.selectedFilter || null },
        })
      );
      dispatch(tableActionCreators.fetchTable(tableName));
    }
  }, [alertEmergencyModeFilter.selectedFilter, dispatch, tableName]);

  return alertEmergencyModeFilter.render();
};

export default AlertEmergencyModeFilter;
