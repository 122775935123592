import React from 'react';
import { translate } from '../../../logic/translations/translations.service';
import { Entity } from '../../../models/entity';
import { SortableFilterableColumn } from './table';
import { SortOrder } from 'antd/lib/table/interface';
import { ColorSwatch } from '../color-swatch/color-swatch';
import { formatPhoneNumberAsInternational } from '../../../logic/phone/phone.mapper';
import { Tag, Tooltip } from 'antd';
import truncate from 'lodash.truncate';
import FormValidationIcon from '../form-validation-icon/form-validation-icon';

export type Render<T> = (value: any, record: T, index: number) => React.ReactNode;

export const getSortableFilterableColumnCreator =
  <T extends string, E = Entity>(translationGroupKey: { [x: string]: string }) =>
  (fieldName: T, sort = true, render?: Render<E>, title?: string): SortableFilterableColumn<E> =>
  (filter, sorter) => {
    const column = {
      title: title !== undefined ? title : translate(translationGroupKey[fieldName]),
      dataIndex: fieldName,
      key: fieldName,
      sorter: sort,
      sortOrder: sorter?.field === fieldName ? sorter?.order : undefined,
      render,
    };
    return column;
  };

export const getColumnCreator =
  <T extends string, E = Entity>(translationGroupKey: { [x: string]: string }) =>
  (fieldName: T, sorter?: (a: E, b: E) => number, render?: Render<E>) => ({
    title: translate(translationGroupKey[fieldName]),
    dataIndex: fieldName,
    key: fieldName,
    sorter,
    render,
    sortDirections: ['descend', 'ascend'] as SortOrder[],
  });

export const renderCheck = (value: boolean) => (value ? <FormValidationIcon isValid /> : '');

export const renderPhone = (phonePayload: string | null | undefined) => formatPhoneNumberAsInternational(phonePayload);

export const renderColorCircle = (color: string | undefined) => (
  <ColorSwatch color={color} className={'color-square'} />
);

export const renderTooltip = (value: string) => <Tooltip title={value}>{truncate(value)}</Tooltip>;

export const renderTag = (value: string, color: string) => <Tag color={color}>{value}</Tag>;
