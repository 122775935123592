import { Col, Row } from 'antd';
import styled from 'styled-components';
import { UserOutlined } from '@ant-design/icons';

import { MasterPageHeader } from '../components/master-page-header/master-page-header';
import { MasterContent } from '../master-page/components/master-content/master-content';
import Title from 'antd/lib/typography/Title';
import { colors, featureColor } from '../../styles/colors';
import { translate, translationKeys } from '../../logic/translations/translations.service';
import SAAppversionsWidget from './components/sa-appversions-widget/sa-appversions-widget';

const SuperAdminDashboard = () => {
  const renderAppVersionWidget = () => <SAAppversionsWidget />;

  const layout = {
    md: 24,
    lg: 12,
  };

  return (
    <>
      <MasterPageHeader
        content={
          <HeaderContainer>
            <UserOutlined style={{ fontSize: '50px', color: colors.curiousBlue }} />
            <Title className={'text-centered'} level={4}>
              <TitleColored color={colors.curiousBlue}>
                {translate(translationKeys.pages.dashboard.header.title2)}
              </TitleColored>
              <TitleColored color={featureColor.secondary}>
                {translate(translationKeys.pages.dashboard.header.title3)}
              </TitleColored>
              {translate(translationKeys.pages.dashboard.header.title5)}
            </Title>
          </HeaderContainer>
        }
      />
      <MasterContent>
        <Row gutter={[16, 16]}>
          <Col {...layout}>{renderAppVersionWidget()}</Col>
        </Row>
      </MasterContent>
    </>
  );
};

const HeaderContainer = styled.div`
  text-align: center;
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const TitleColored = styled.span<{ color: string }>`
  color: ${({ color }) => color};
  font-weight: 800;
`;

export default SuperAdminDashboard;
